// @vendors
import React, { useMemo } from 'react'
import {
    Button,
    Dialog,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';

// @assets
import { DialogFilterPayroll, EditPayrollStyle, PayrollStyle } from '../../../../assets/css/payroll-style'
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';

const YTDModal = ({open, handleClose, dataYtd}) => {
    const style = useMemo(() => EditPayrollStyle(), []);
    const stylesE = useMemo(() => EmployeeTableStyles(), []);
    const styleButtons = useMemo(() => DialogFilterPayroll(), []);
    const styleP = useMemo(() => PayrollStyle(), []);

  return (
    <Dialog
        sx={style.dialogEdit}
        open={open}
        fullWidth={true}
        maxWidth="lg"
    >
        <Box sx={{...style.body, minHeight: '150px'}}>
            <TableContainer sx={{...stylesE.tableContainer, minHeight: '150px',}}>
                <Table aria-label="table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{...styleP.tableHeadTitleYTD, textAlign: 'start'}}>Date</TableCell>
                            <TableCell sx={styleP.tableHeadTitleYTD}>YTD Gross Wages ($)</TableCell>
                            <TableCell sx={styleP.tableHeadTitleYTD}>YTD Hours</TableCell>
                            <TableCell sx={styleP.tableHeadTitleYTD}>Invoice YTD ($)</TableCell>
                            <TableCell sx={styleP.tableHeadTitleYTD}>Actual Invoice ($)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataYtd?.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{...styleP.tableCell, textAlign: 'start'}}>{value?.month}-{value?.year}</TableCell>
                                    <TableCell sx={styleP.tableCell}>{value?.currentMonthGrossWage}</TableCell>
                                    <TableCell sx={styleP.tableCell}>{value?.currentMonthHours}</TableCell>
                                    <TableCell sx={styleP.tableCell}>{value?.ytdInvoice}</TableCell>
                                    <TableCell sx={styleP.tableCell}>{value?.actualInvoice}</TableCell>
                                </TableRow>   
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Box>
                <Typography
                    sx={{
                        fontSize: '1rem',
                        color: '#BCBCBC',
                    }}
                >
                    * {`You have reached Max Tax Credit...(if Tax Credit YTD >= Max Tax Credit)`}
                </Typography>
            </Box>

            <Box sx={{...styleButtons.divActions}}>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={styleButtons.buttonCancel}
                >
                    Close
                </Button>
            </Box>
        </Box>
    </Dialog>
  )
}

export default YTDModal