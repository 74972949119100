// @vendors
import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// @state
import { useUI } from "../../../app/context/ui";

// @assets
import { DashboardStyles } from "../../../assets/css/dashboard-style";
import {
  componentsStyles,
  MenuProps,
} from "../../../assets/css/tableComponents-styles";
import CustomNoRowsOverlay from "../../../assets/CustomNoRowsOverlay";

// @material
import {
  Box,
  Button,
  ButtonGroup,
  DataGrid,
  EditIcon,
  ExpandMoreRoundedIcon,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "../../../components/shared/MaterialUI";

// @helpers
import AppHelper from "../../../helpers/AppHelper";
import { permissionsForEditEmployer } from "../../../helpers/FunctionsUtils";

// @components
import { DataTable } from "../Admin";
import { CustomFiltering } from "./custom/CustomFiltering";
import { CustomPagination } from "./custom/CustomPagination";
import { CustomColumnsPanel } from "./custom/CustomPreferences";
import { CustomToolbar } from "./custom/CustomToolbar";

// @services
import CompanyServiceNewApi from "../../../services/newApi/CompanyServiceNewApi";

// @constants
import { ROUTENAME } from "../../../navigation/RouteName";

export const ClientTable = () => {
  const {
    rowsState,
    setRowsState,
    getAllCompanies,
    sortModel,
  } = useContext(DataTable);
  const styles = useMemo(() => DashboardStyles(), []);
  const { blockUI, snackbarUI } = useUI();
  const companyService = useMemo(() => new CompanyServiceNewApi(), []);
  const user = useSelector((state) => state?.user);

  const onStatusChange = async (e, row) => {
    try {
      blockUI.current.open(true);
      await companyService.updateCompanyStatus(row?.id, e.target.value);
      let newRows = rowsState.rows.map((r) => 
        (r.id === row?.id ? { ...r, status: e.target.value } : r)
      );
      setRowsState({...rowsState, rows: newRows});
      getAllCompanies()
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }  
  };

  let statusOptions = ["active", "suspended", "inactive"];

  const columns = [
    {
      field: "name",
      headerName: "Company name",
      flex: 0.5,
      renderCell: (params) => (
        <Box
          component={Link}
          to={{
            pathname: `${ROUTENAME.employer}${params.row.id}`,
            state: { page: rowsState.page + 1 },
          }}
          sx={styles.employerLink}
        >
          {params?.row?.name}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Company Status",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.2,
      minWidth: 160,
      renderCell: (params) => (
        <Select
          value={params.row.status}
          IconComponent={ExpandMoreRoundedIcon}
          onChange={(e) => onStatusChange(e, params.row)}
          sx={{...styles.statusSelect, pointerEvents: permissionsForEditEmployer(user?.role) ? 'auto' : 'none'}}
          MenuProps={MenuProps}
        >
          {statusOptions.map((st, index) => (
            <MenuItem
              value={st}
              key={index}
              disabled={st === params.row.status}
              sx={{ textTransform: "capitalize" }}
            >
              {st}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "actionButtons",
      headerName: "",
      filterable: false,
      flex: 0.05,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        permissionsForEditEmployer(user?.role)
        ? <ButtonGroup
            variant="contained"
            size="medium"
            sx={styles.buttonTools}
            fullWidth={true}
          >
            <Tooltip
              title={<p style={{ fontSize: "16px", margin: "0" }}>Edit user</p>}
              arrow
            >
              <Link
                to={{
                  pathname: `${ROUTENAME.editEmployer}${params.row.id}`,
                  state: { page: rowsState.page + 1 },
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: "transparent" }}
                  children={<EditIcon style={{}} />}
                />
              </Link>
            </Tooltip>
          </ButtonGroup>
        : null
      ),
    },
  ];

  const sortData = (sort) => {
    let orderByName = null
    let orderBy = null
    if(sort?.length){
      orderByName = true
      orderBy = sort[0]?.sort
    }
    setRowsState((prev) => ({
      ...prev,
      pageSize: rowsState?.pageSize,
      currentPage: rowsState?.currentPage,
      sortBy: {hasOrder: orderByName, orderBy:orderBy}
  }));
    callCompanies(sort, orderByName, orderBy);
  };

  const callCompanies = (sort, orderByName, orderBy) => {
    const params = {
      page: rowsState?.currentPage,
      size: rowsState?.pageSize, 
      filters: rowsState?.filters,
      sort: sort,
      order: orderByName,
      orderBy: orderBy
    }
    return getAllCompanies(params);
  }

  return (
    <Paper elevation={4} sx={styles.wrapperTable}>
      <DataGrid
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
          Pagination: CustomPagination,
          FilterPanel: CustomFiltering,
          ColumnsPanel: CustomColumnsPanel,
        }}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => sortData(newSortModel)}
        componentsProps={componentsStyles}
        headerHeight={80}
        rowHeight={70}
        paginationMode="server"
        pagination
        filterMode="server"
        disableSelectionOnClick
        checkboxSelection={false}
        sx={styles.tableGrid}
        {...rowsState}
      />
    </Paper>
  );
};
