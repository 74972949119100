// @vendors
import React from 'react';

// @assets
import { PublicHeaderStyles } from '../assets/css/general-style';
import logo from '../assets/images/logoDashboardPublic.svg';

// @navigation
import { MainListItems } from '../navigation/listItems';
import { MobileListItems } from '../navigation/MobileListItems';
import { ROUTENAME } from '../navigation/RouteName';

// @componnets-material
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  IconButton,
  Link,
  Menu,
  MenuIcon,
  Toolbar,
} from './shared/MaterialUI';

const HeaderPublic = (props) => {
  const styles = PublicHeaderStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <CssBaseline />
      <AppBar position='static' sx={styles.appBar}>
        <Toolbar sx={styles.toolbar}>
          <Box sx={styles.mobileMenu}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              sx={{ padding: 0 }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'column',
              }}
            >
              <MobileListItems type='public' func={handleCloseNavMenu} />
            </Menu>
          </Box>

          <div>
            <img src={logo} alt='dashboard public' className='Logo' />
          </div>

          <Box sx={styles.menuItems}>
            <MainListItems type='public' />
          </Box>

          <Box sx={styles.wrapperLog}>
            {!props?.type && (
              <>
                <Link href={ROUTENAME.login} underline='none'>
                  <Button variant='text' sx={styles.btnSignIn}>
                    Sign In
                  </Button>
                </Link>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default HeaderPublic;
