import AppServiceNewApi from "./AppServiceNewApi";

class OrganizationServiceNewApi extends AppServiceNewApi{
    constructor() {
        super();
        this.path = '/api/internal/v1/organization';
    }
    
    getOrganization(){
        return this.http.get(`${this.path}`);
    }
    
    getOrganizationById(id){
        return this.http.get(`${this.path}/${id}`);
    }

    createOrganization(body){
        return this.http.post(`${this.path}`, body);
    }
    
    addLogo(id, body){
        return this.http.post(`${this.path}/${id}`, body);
    }
    
    updateOrganization(id, body){
        return this.http.put(`${this.path}/${id}`, body);
    }

    updateLastOrganization(organizationId) {
        return this.http.put(`${this.path}/last-organization?organizationId=${organizationId}`, {})
    }

    // call ocr
    ocrTrigger(){
        return this.http.post('/api/internal/v1/job/process-employees-state', {})
    }

}
export default OrganizationServiceNewApi;