// @vendors
import * as React from 'react';
import { useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { Button, Typography } from '@mui/material';

const Style = () => ({
  body:{
    padding: '40px'
  },
  title:{
    fontSize: "20px",
    display: 'flex',
    fontWeight: '500',
    color: '#101010',
    margin: '0px',
    textAlign: 'center',
  },
  containerButtons:{
    width: '100%',
    display: 'flex',
    gap: '15px',
    justifyContent: 'end',
    marginTop: '30px'
  },
  buttonAccept: {
    fontSize: '0.8rem',
    color: '#fff',
    padding: '10px',
    backgroundColor: '#101010',
    borderRadius: 1,
    ':hover': {
      backgroundColor: '#101010',
      boxShadow: 2,
    },
  },
  buttonClose: {
    fontSize: '0.8rem',
    color: '#fff',
    padding: '10px',
    backgroundColor: 'rgba(36, 35, 35, 0.3)',
    borderRadius: 1,
    border: 'none',
    ':hover': {
      backgroundColor: 'rgba(36, 35, 35, 0.3)',
      boxShadow: 2,
    },
  },
})

function ConfirmDialog(props) {
  const { onClose, open, row, title} = props;
  const radioGroupRef = useRef(null);
  const style = Style()

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = (value) => {
    onClose(value, row);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogContent sx={style.body}>
        <Typography sx={style.title}>
          {title ? title : 'Are you sure you would like to make this change?'}
        </Typography>
        <div style={style.containerButtons}>
          <Button
            autoFocus
            onClick={() => handleCancel('no')}
            sx={style.buttonClose}
          >
            Cancel
          </Button>
          <Button
            sx={style.buttonAccept}
            onClick={() => handleCancel('yes')}
          >
            Accept
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
export default ConfirmDialog
