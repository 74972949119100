// @vendors
import React, { useContext } from 'react'
import {
  Divider,
  Fade,
  Menu,
  MenuItem,
} from '@mui/material'
import { useSelector } from 'react-redux';

// @assets
import { MenuExcelStyle } from '../../../../assets/css/payroll-style';

// @services
import PayrollService from '../../../../services/newApi/PayrollService';

// @state
import { payrollContext } from '../../payrollView';
import { useUI } from '../../../../app/context/ui';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';
import { downloadTemplate } from '../../../../helpers/FunctionsUtils';

const ExportToExcel = ({anchorEl, handleClose}) => {
  const style = MenuExcelStyle()
  const open = Boolean(anchorEl);
  const payrollService = new PayrollService();
  const { filterModal, currentTab } = useContext(payrollContext);
  const state = useSelector((state) => state);
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const { blockUI, snackbarUI } = useUI();

  const downloadData = async (filtered) => {
    const orgId = filterModal?.organization ?? organizationId;
    const filters = filtered ? filterModal : {};
    const isMaxedOut = currentTab === 2 ? true : false;
    const fileName = currentTab === 2 ? 'payroll_employees_maxedOut.csv' : 'payroll_employees.csv';
    try {
      blockUI.current.open(true);
      const response = await payrollService.downloadFile(orgId, filters, isMaxedOut);
      if(!response?.data)return emptyData();
      blockUI.current.open(false);
      handleClose();
      return downloadTemplate(response?.data, fileName);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const emptyData = () => {
    AppHelper.showCustomMessage('No employees were found for the applied filters', snackbarUI);
    blockUI.current.open(false);
    handleClose();
  }
    
  return (
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button'}}
        sx={style.containerMenu}
        transitionDuration={25}
        TransitionComponent={Fade}
        disableAutoFocusItem={true}
      >
        <MenuItem onClick={() => downloadData()} sx={style.menuItem}>Entire Data</MenuItem>
        <Divider/>
        <MenuItem onClick={() => downloadData(true)} sx={style.menuItem}>Filtered Data Only</MenuItem>
      </Menu>
  )
}

export default ExportToExcel