// !vendors
import { useState, useCallback, useMemo } from 'react';

// @services
import StatsServiceNewApi from '../../../services/newApi/StatsServiceNewApi';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';

const useStats = (organizationId) => {
    const statsServicesNewApi = useMemo(() => new StatsServiceNewApi(), []);
    const { snackbarUI } = useUI();
    const [stats, setStats] = useState({});

    const fetchStats = useCallback(async () => {
      setStats({});
        try {
          const response = await statsServicesNewApi.getStats(organizationId, '');
          setStats(response?.data?.data || {});
        } catch (e) {
          AppHelper.checkError(e, snackbarUI);
        }
    }, [organizationId, statsServicesNewApi, snackbarUI]);

  return { stats, fetchStats };
};

export default useStats;