// @vendors
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';

// @context
import { useUI } from '../../../app/context/ui';

// @services
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

const useAdmin = () => {
    const { blockUI, snackbarUI } = useUI();
    const companySNewApi = useMemo(() => new CompanyServiceNewApi(), []);
    const state = useSelector((loadedState) => loadedState);
    const organizationId = state?.organizationReducer?.organizationSelected?.id;
    const [sortModel, setSortModel] = useState([]);

    const [rowsState, setRowsState] = useState({
        pageSize: 10,
        rows: [],
        rowCount: 0,
        currentPage: 0,
        page: 0,
        filters: { name: '', status: '' },
        sortBy: {hasOrder: null, orderBy:null}
    });

    const successRequestCompanies = (response) => {
        blockUI.current.open(false);
        const data = response?.data;
        setRowsState((prev) => ({
            ...prev,
            pageSize: data?.pagination?.page_size || 10,
            rows: data?.data || [],
            rowCount: data?.pagination?.total_records || 0,
            currentPage: data?.pagination?.page_number,
            page: data?.pagination?.page_number -1,
        }));
      }

    const getAllCompanies = useCallback(async (params) => {
        const pagination = {page: params?.page ?? 1, size: params?.size ?? rowsState.pageSize}
        const filters = params?.filters || rowsState.filters;
        const sort = {order: params?.order ?? rowsState?.sortBy?.hasOrder, direction: params?.orderBy ?? rowsState?.sortBy?.orderBy }
        try {
            blockUI.current.open(true);
            const response = await companySNewApi.getCompanies(pagination, organizationId, filters, sort)
            setSortModel(params?.sort)
            successRequestCompanies(response)
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }finally {blockUI.current.open(false)}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, rowsState, companySNewApi, blockUI, snackbarUI]);

  return {
    rowsState,
    getAllCompanies,
    setRowsState,
    sortModel,
  }
}

export default useAdmin