// @vendors
import React, { useMemo, useState } from "react";
import {Form, Formik} from "formik";
import { useDispatch } from "react-redux";

// @state
import {useUI} from "../../../../app/context/ui";

// @assets
import {CreateEmployeeStyles} from "../../../../assets/css/createEmployee-style";

// @components
import {
  AdapterDateFns,
  Box,
  Button,
  Container,
  LocalizationProvider,
  Typography,
} from "../../../../components/shared/MaterialUI";
import {AppTextField} from "../../../../components/forms";

// @helpers
import AppHelper from "../../../../helpers/AppHelper";
import {validationEmployee} from '../helpers/validationEmployee';

// @services
import EmployeeCompanyServiceNewApi from "../../../../services/newApi/EmployeeCompanyServiceNewApi";

// @constants
import { types } from "../../../../redux/types";

const employeeValue = {
  firstName: "",
  lastName: "",
  email: "",
  externalEmployeeId: "",
}

const CreateEmployee = (props) => {
  const {dialogUI, blockUI, snackbarUI} = useUI();
  const styles = useMemo(() => CreateEmployeeStyles(), []);
  const [employeeValues, setEmployeeValues] = useState(employeeValue);
  const initialValues = employeeValue;
  const employeeServiceNewApi = useMemo(() => new EmployeeCompanyServiceNewApi(), []);
  const {token, setMessage} = props;
  const dispatch = useDispatch();
  
  const settings = {
    confirm: true,
    btn: {
      confirm: "Close",
      close: "",
    },
    onConfirm: () => {
      dialogUI.current.close();
    },
    styles: {...styles.dialog},
  };

  const onSubmit = (values) => {
    try {
      blockUI.current.open(true);
      dataToSend(values)
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const dataToSend = async (values) => {
    dispatch({
      type: types.ID_EMPLOYEE_SELECTED,
      detail: ''
    })
    if (token) {
      return sendEmployeeExternal(values)
    } else {
      return sendEmployeeInternal(values)
    }
  }

  const sendEmployeeExternal = async (values) => {
    try {
      const data = valuesToSendExternal(values)
      const res = await employeeServiceNewApi.addEmployeeExternal(data, token)
      setMessage(res?.data?.data[0]);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }
  
  const sendEmployeeInternal = async (values) => {
    try {
      const data = valuesToSendInternal(values)
      await employeeServiceNewApi.addEmployee(data, props?.companyId)
      props.getEmployees();
      openModalAccountCreated()
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const valuesToSendExternal = (values) => {
    const DATA = {
      phone_number: null,
      first_name: values?.firstName,
      middle_name: null,
      last_name: values?.lastName,
      email: values?.email,
      address: null,
      state: null,
      city: null,
      county: null,
      zip_code: null,
      social_number: null,
      date_of_birth: null,
      occupation_code: null,
      date_gave_info: null,
      date_was_offered_job: null,
      date_was_hired: null,
      date_started_job: null,
      hourly_start_wage: 0,
      job_position: null,
      subcription: true,
      is_manual: false,
      summer_youth_emp_zone: false,
      external_employeeId: values.externalEmployeeId ? values.externalEmployeeId : null,
      state_certification: null
    }
    return DATA
  }

  const valuesToSendInternal = (values) => {
    const DATA = {
      phoneNumber: null,
      firstName: values?.firstName,
      middleName: null,
      lastName: values?.lastName,
      email: values?.email,
      address: null,
      state: null,
      city: null,
      county: null,
      zipCode: null,
      socialNumber: null,
      dateOfBirth: null,
      occupationCode: null,
      dateGaveInfo: null,
      dateWasOfferedJob: null,
      dateWasHired: null,
      dateStartedJob: null,
      hourlyStartWage: 0,
      jobPosition: null,
      subcription: true,
      isManual: false,
      summerYouthEmpZone: false,
      stateCertification: null,
      externalEmployeeId: values.externalEmployeeId ? values.externalEmployeeId : null
    }
    return DATA
  }

  const openModalAccountCreated = () => {
    setTimeout(() => {
      setEmployeeValues(initialValues);
      blockUI.current.open(false);
      dialogUI.current.open(
        "Account created!",
        "The account has been created successfully. \n An email with the link to the questionnaire has been sent.",
        settings
      );
    }, 500);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container
        component="div"
        maxWidth={false}
        sx={styles.container}
      >
        <Typography variant="h3">Create new employee</Typography>
        <Formik
          initialValues={employeeValues}
          validationSchema={validationEmployee}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          enableReinitialize={true}
        >
          {() => {
            return (
              <Form autoComplete="off" className="formCreateUser">
                <Box sx={styles.inputsCtn}>
                  <Box sx={styles.boxItem}>
                    <p>
                      First name: <span style={{color: "red"}}>*</span>
                    </p>
                    <AppTextField
                      sx={styles.input}
                      label="Type your first name here"
                      variant="outlined"
                      id="firstName"
                      name="firstName"
                    />
                  </Box>

                  <Box sx={styles.boxItem}>
                    <p>
                      Last name: <span style={{color: "red"}}>*</span>
                    </p>
                    <AppTextField
                      sx={styles.input}
                      label="Type your last name here"
                      variant="outlined"
                      id="lastName"
                      name="lastName"
                    />
                  </Box>

                  <Box sx={styles.boxItem}>
                    <p>
                      Email: <span style={{color: "red"}}>*</span>
                    </p>
                    <AppTextField
                      sx={styles.input}
                      label="example@email.com"
                      variant="outlined"
                      autoComplete="new-password"
                      id="email"
                      name="email"
                      type="email"
                    />
                  </Box>

                  <Box sx={styles.boxItem}>
                    <p>
                      External employee id:
                    </p>
                    <AppTextField
                      sx={styles.input}
                      label="External employee id"
                      variant="outlined"
                      id="externalEmployeeId"
                      name="externalEmployeeId"
                    />
                  </Box>
                </Box>
                <Box sx={styles.formButtonWrapper}>
                  <Button
                    color="inherit"
                    onClick={() => {
                      dialogUI.current.close();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit">Create</Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </LocalizationProvider>
  );
};

export default CreateEmployee;
