/* eslint-disable no-use-before-define */
// @vendors
import React, { createContext, useEffect, useMemo } from 'react'
import { Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// @components
import SearchEmployeeTable from './components/SearchEmployeeTable';
import { StatsBoxes } from '../Admin/components/StatsBoxes';

//@assets
import { EmployerStyles } from '../../assets/css/employer-style';

// @constants
import { types } from '../../redux/types';
import useStats from './hooks/UseStats';
import useEmployees from './hooks/UseEmployees';

const SearchEmployees = () => {
  //const SearchEmployeeTable = lazy(() => import('./SearchEmployeeTable'));
  const styles = useMemo(() => EmployerStyles(), []);
  const dispatch = useDispatch()
  const state = useSelector((loadedState) => loadedState);
  const sort = state.sortEmployeeReducer;
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const {
    tableState,
    setTableState,
    fetchAllEmployees
  } = useEmployees(organizationId, sort);
  const {stats, fetchStats} = useStats(organizationId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch({
      type: types.ID_EMPLOYEE_SELECTED,
      detail: ''
    })
    dispatch({
      type: types.SORT_EMPLOYEE,
      sort: '',
      direction: ''
    })
    //fetchAllEmployees();
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId])

  return (
    <Grid component="section" sx={styles.dashCtn} >
      <Grid>
        <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "bold" }}>
          List all employees
        </Typography>
      </Grid>
      <Grid>
        <StatsBoxes stats={stats} />
      </Grid>
      <SearchEmployeeContext.Provider
        value={{
          tableState,
          setTableState,
          fetchAllEmployees
        }}
      >
        <SearchEmployeeTable />
      </SearchEmployeeContext.Provider>
    </Grid>
  )
}

export const SearchEmployeeContext = createContext({});
export default SearchEmployees