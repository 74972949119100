// @vendors
import { useState, useCallback, useMemo } from 'react';

// @services
import EmployeeCompanyServiceNewApi from '../../../services/newApi/EmployeeCompanyServiceNewApi';

// @helpers
import AppHelper from '../../../helpers/AppHelper';
import { useUI } from '../../../app/context/ui';

const useEmployees = (organizationId, sort) => {
  const employeeByCompanyService = useMemo(() => new EmployeeCompanyServiceNewApi(), []);
  const { blockUI, snackbarUI } = useUI();

  const [tableState, setTableState] = useState({
    pageSize: 10,
    rows: [],
    rowCount: 0,
    currentPage: 0,
    page: 0,
    filters: { name: '', status: '' },
  });

  const fetchAllEmployees = useCallback(async (params) => {
      const paginattion = {page: params?.page ?? 1, size: params?.size ?? tableState.pageSize};
      const filters = params?.filters || tableState.filters;
      const sortBy = {order: params?.sort ?? sort.sort, direction: params?.direction ?? sort.direction};
      try {
        blockUI.current.open(true);
        const response = await employeeByCompanyService.getEmployeesByCompany('', organizationId, paginattion, filters, sortBy);
        const data = response?.data;
        updateTableState(data);
      } catch (e) {
        blockUI.current?.open(false);
        AppHelper.checkError(e, snackbarUI);
      }finally {blockUI.current.open(false);}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, tableState, employeeByCompanyService, blockUI, snackbarUI]);

  const updateTableState = (data) => {
      setTableState((prev) => ({
        ...prev,
        pageSize: data?.pagination?.page_size || 10,
        rows: data?.data || [],
        rowCount: data?.pagination?.total_records || 0,
        currentPage: data?.pagination?.page_number,
        page: data?.pagination?.page_number -1,
      }));
  };

  return { tableState, setTableState, fetchAllEmployees };
};

export default useEmployees;
