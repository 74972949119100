// @vendors
import React, {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';

// @styles
import { StyleAPiClient } from './StyleApiClient.js';

// @components
import {
    Box,
    Container,
    Typography,
} from "../../components/shared/MaterialUI.js";
import TableApiClient from './TableApiClient.js';

// @helpers
import AppHelper from '../../helpers/AppHelper.js';

// @service
import ClientServiceNewApi from '../../services/AppServiceClient/ClientService.js';

// @state
import { useUI } from '../../app/context/ui.js';
import store from '../../redux/store.js';

// @constants 
import { ROUTENAME } from '../../navigation/RouteName.js';

const ApiClients = () => {
  const [data, setData] = useState([])
  const styles = StyleAPiClient()
  const clientService = new ClientServiceNewApi()
  const { blockUI, snackbarUI } = useUI();
  const history = useHistory();
  const state = store.getState()

  const getAllClients = async () => {
    try {
      blockUI.current.open(true);
      const response = await clientService.getClients()
      const newList = response?.data?.sort((a, b) => {
        if(a?.name?.toLowerCase() < b?.name?.toLowerCase())return -1
        if(a?.name?.toLowerCase() > b?.name?.toLowerCase())return 1
        return 0
    })
      setData(newList)
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  useEffect(() => {
    if(state?.user?.hasApiAccess && state?.user?.hasNewToken) getAllClients();
    else history.push(ROUTENAME.dashboard);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      component="section"
      maxWidth="xl"
      sx={styles.containerApiClient}
    >
      <Box sx={styles.header}>
        <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "bold" }}>Api Clients</Typography>
      </Box>

      <TableApiClient data={data} getAllClients={getAllClients}/>
    </Container>
  )
}

export default ApiClients