/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @image
import { DashboardStyles } from "../../assets/css/dashboard-style";

// @components
import {
  Box,
  Container,
  CssBaseline,
} from "../../components/shared/MaterialUI.js";
import { ClientTable } from "./components/ClientTable";
import { StatsBoxes } from "./components/StatsBoxes";
import Pageheader from "../../components/PageHeader.js";

// @helpers
import { ROUTENAME } from "../../navigation/RouteName.js";
import useStats from "../searchEmployer/hooks/UseStats.js";
import useAdmin from "./hooks/UseAdmin.js";

const Dashboard = () => {
  const state = useSelector((loadedState) => loadedState);
  const history = useHistory();
  const user = state.user;
  const styles = useMemo(() => DashboardStyles(), []);
  const dispatch = useDispatch();
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const organizationName = state?.organizationReducer?.organizationSelected?.name;

  if (!user?.accessToken) {
    history.push(ROUTENAME.login);
  }

  const {
    rowsState,
    getAllCompanies,
    setRowsState,
    sortModel,
  } = useAdmin();

  const {stats, fetchStats} = useStats(organizationId);

  useEffect(() => {
    if(user?.hasNewToken && !user?.expiryTime){
      dispatch({ type: 'LOGOUT' })
    };
  }, [])

  useEffect(() => {
    getAllCompanies();
    fetchStats();
  }, [organizationId])

  return (
    <>
      <CssBaseline />
      <Container
        component="section"
        maxWidth="xl"
        sx={styles.dashCtn}
      >
        <Container
          component="div"
          maxWidth="xl"
          justify="space-between"
          sx={styles.upperCtn}
        >
          <Pageheader
            title={'Dashboard'}
            subtitle={`Welcome to ${organizationName} dashboard`}
          />
        </Container>
        <Box
          maxWidth="xl"
          sx={styles.buttonCtn}
        >
        <StatsBoxes stats={stats} />
        </Box>
        <DataTable.Provider
          value={{
            rowsState,
            getAllCompanies,
            setRowsState,
            sortModel
          }}
        >
          <ClientTable accessToken={user?.accessToken} />
        </DataTable.Provider>
      </Container>
    </>
  );
};

export const DataTable = createContext({});
export default Dashboard;
