/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { createContext, useEffect, useState } from 'react'
import {
  Breadcrumbs,
  Button,
  Link,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// @assets
import { PayrollStyle } from '../../assets/css/payroll-style'

// @components
import PayrollTable from './PayrollTable/PayrollTable'
import PayrollFilter from './PayrollTable/PayrollFilter.js';

// @helpers
import AppHelper from '../../helpers/AppHelper.js';

// @service
import PayrollService from '../../services/newApi/PayrollService.js';

// @state
import { useUI } from '../../app/context/ui.js';
import { useSelector } from 'react-redux';

const PayrollView = () => {
  const style = PayrollStyle();
  const state = useSelector((state) => state);
  const organizationId = state?.organizationReducer?.organizationSelected?.id;
  const [payrollList, setPayrollList] = useState([]);
  const [dataInvoice, setDataInvoice] = useState([]);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const payrollService = new PayrollService();
  const { blockUI, snackbarUI } = useUI();
  const [currentTab, setCurrentTab] = useState(1);
  const [pages, setPages] = useState({
    page:0,
    size:10,
    totalRows: 0
  });

  const [filterModal, setFilterModal] = useState({})

  const breadcrumbs = [
    <Link key="1" color="inherit" sx={style.breadcrumbsText}>
      Dashboard
    </Link>,
    <Link
      key="2"
      color="inherit"
      sx={style.breadcrumbsText}
      //href="/material-ui/getting-started/installation/"
    >
      Reports
    </Link>,
    <Typography key="3" color="text.primary" sx={style.breadcrumbsTextSelected}>
      Payroll
    </Typography>,
  ];

  const getRecordsList = (params) => {
    setPayrollList([])
    const paramsUrl = {
      page: params?.page ?? 1,
      size: params?.size ?? pages?.size
    }
    const filters = params?.filters ?? filterModal;
    const orgId = params?.filters?.organization ?? organizationId;
    if(currentTab === 1) getListEmployees(orgId, paramsUrl, filters, false);
    if(currentTab === 2) getListEmployees(orgId, paramsUrl, filters, true);
    if(currentTab === 3) getListForInvoiceSummary(orgId);
  }

  useEffect(() => {
    getRecordsList({filters:{}});
  }, [currentTab, organizationId])

  const getListEmployees = async (orgId, params, filters, isMaxedOut) => {
    try {
      blockUI.current.open(true);
      const response = await payrollService.getEmployeesActiveTab(orgId, params, filters, isMaxedOut)
      updatePagination(response?.data?.pagination)
      setPayrollList(response?.data)
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }
  
  const getListForInvoiceSummary = async (orgId) => {
    try {
      blockUI.current.open(true);
      const response = await payrollService.getInvoiceSummary(orgId);
      const addId = response?.data?.data?.map((item) => {
        return {...item, id: Math.floor(Math.random() * 900) + 100}
      })
      setDataInvoice(addId);
      blockUI.current.open(false);
    } catch (error) {
      blockUI.current.open(false);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  const updatePagination = (pagination) => {
    setPages((prev) => ({
      ...prev,
      page: pagination?.pageNumber ?? 1,
      size: pagination?.pageSize ?? 10,
      totalRows: pagination?.totalRecords ?? 0
    }));
  }

  const handleCloseOrOpenModalFilter = () => setOpenModalFilter((open) => !open)

  return (
    <payrollContext.Provider
      value={{
        currentTab,
        setCurrentTab,
        getRecordsList,
        pages,
        setPages,
        payrollList,
        handleCloseOrOpenModalFilter,
        openModalFilter,
        setFilterModal,
        filterModal,
        dataInvoice
      }}
    >
      <Box sx={style.containerPayroll}>
        <Box sx={style.breadcrumbs}>
          <Breadcrumbs
            separator={<NavigateNextIcon sx={style.separatorBreadcrumbs} />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>

          {currentTab === 3
            ? null
            : <Button
                variant="contained"
                startIcon={<FilterAltIcon sx={style.iconBtnFilter}/>}
                sx={style.btnFilter}
                onClick={handleCloseOrOpenModalFilter}
              >
                Filters
              </Button>
          }
        </Box>

        <PayrollFilter/>
          
        <Box>
          <PayrollTable />
        </Box>
      </Box>
    </payrollContext.Provider>
  )
}

export const payrollContext = createContext({});
export default PayrollView