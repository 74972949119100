import AppServiceNewApi from "./AppServiceNewApi";

class PayrollService extends AppServiceNewApi{
    constructor(){
        super();
        this.path = '/payroll/'
    }

    getEmployeesActiveTab(organizationId, pagination, filters, isMaxedOut){
        let filterString = '&'
        if(filters?.Employeer)filterString = `${filterString}Employeer=${filters?.Employeer}&`
        if(filters?.StartDateFrom)filterString = `${filterString}StartDateFrom=${filters?.StartDateFrom}&`
        if(filters?.StarDateTo)filterString = `${filterString}StarDateTo=${filters?.StarDateTo}&`
        if(filters?.EmployeeName)filterString = `${filterString}EmployeeName=${filters?.EmployeeName}&`
        if(filters?.StateStatus)filterString = `${filterString}StateStatus=${filters?.StateStatus}&`
        //if(filters?.StateCertification || filters?.StateCertification === false)filterString = `${filterString}StateCertification=${filters?.StateCertification}&`
        if(filters?.targetGroup?.length)filterString = `${filterString}TargetGroup=${filters?.targetGroup}&`
        if(filterString === '&')filterString = ''
        else filterString = filterString.substring(0, filterString?.length - 1)
        const URL = `${this.path}Employees?OrganizationId=${organizationId}&PageNumber=${pagination.page}&PageSize=${pagination.size}${filterString}&MaxedOut=${isMaxedOut}`
        return this.http.get(URL)
    }

    getStateStatus(){
        return this.http.get(`${this.path}Helpers/state-status`)
    }
    
    getPaySchedule(){
        return this.http.get(`${this.path}Helpers/pay-schedules`)
    }

    updateEmploye(id, body){
        return this.http.put(`${this.path}Employees/${id}`, body)
    }

    targetGroups(){
        return this.http.get(`${this.path}TargetGroup/groups`)
    }

    downloadFile(organizationId, filters, isMaxedOut){
        let filterString = '&'
        if(filters?.Employeer)filterString = `${filterString}Employeer=${filters?.Employeer}&`
        if(filters?.StartDateFrom)filterString = `${filterString}StartDateFrom=${filters?.StartDateFrom}&`
        if(filters?.StarDateTo)filterString = `${filterString}StarDateTo=${filters?.StarDateTo}&`
        if(filters?.EmployeeName)filterString = `${filterString}EmployeeName=${filters?.EmployeeName}&`
        if(filters?.StateStatus)filterString = `${filterString}StateStatus=${filters?.StateStatus}&`
        if(filters?.StateCertification || filters?.StateCertification === false)filterString = `${filterString}StateCertification=${filters?.StateCertification}&`
        if(filters?.targetGroup?.length)filterString = `${filterString}TargetGroup=${filters?.targetGroup}&`
        if(filterString === '&')filterString = ''
        else filterString = filterString.substring(0, filterString?.length - 1)
        return this.http.get(`${this.path}Helpers/download-employees-csv?organizationId=${organizationId}${filterString}&MaxedOut=${isMaxedOut}`)
    }
    
    downloadFileByDetail(employeeId ){
        return this.http.get(`${this.path}Helpers/download-employee-ytd-csv?employeeId=${employeeId}`)
    }

    uploadManual(body){
        return this.http.post(`${this.path}Files/upload-manual`, body);
    }

    getYtdByEmployees(employeeId){
        return this.http.get(`${this.path}Employees/ytd/${employeeId}`);
    }

    updateHourAndWage(employeeId, body){
        return this.http.put(`${this.path}Employees/manual-data/${employeeId}`, body);
    }

    //invoice summary tab

    getInvoiceSummary(organizationId){
        return this.http.get(`${this.path}Companies/ytd?organizationId=${organizationId}`)
    }

    updateInvoiceStatus(params){
        const query = `companyId=${params.companyId}&year=${params.year}&month=${params.month}&invoiceSent=${params.invoice}`
        return this.http.put(`${this.path}Companies/ytd/invoice-status?${query}`)
    }

    donwloadExcelForInvoice(organizationId){
        return this.http.get(`${this.path}Helpers/download-employers-csv?organizationId=${organizationId}`)
    }
}
export default PayrollService