// @vendors
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";

// @context
import { useUI } from "../../../../app/context/ui.js";

// @assets
import { EmployeeTableStyles } from "../../../../assets/css/employeeTable-style.js";

// @components material
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  IconButton,
} from "../../../../components/shared/MaterialUI.js";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions.js";

// @helpers
import AppHelper from "../../../../helpers/AppHelper.js";

// @services
import TaskService from "../../../../services/newApi/TaskService";

// @components
import EmployeeFormRow from "./components/EmployeeFormRow.js";

// @constants
import { ROUTENAME } from "../../../../navigation/RouteName.js";

const EmployeeForms = (props) => {
  const { id: companyId } = props;

  const styles = EmployeeTableStyles();
  const { blockUI, snackbarUI } = useUI();
  const taskService = new TaskService();

  const history = useHistory();

  const [rowsState, setRowsState] = useState({
    pageSize: 20,
    rows: [],
    rowCount: 0,
    page: 1,
  });

  const handlePageChange = (nextPage) => {
    getTasks(rowsState.pageSize, nextPage + 1, companyId);
  };

  const handlePageSizeChange = (pageSize) => {
    getTasks(pageSize, 1, companyId);
  };

  const getTasks = async (pageSize, page, companyId) => {
    try {
      blockUI.current.open(true);
      const res = await taskService.getTasks(pageSize, page, companyId);
      const { data, pagination } = res.data;

      setRowsState((prev) => ({
        ...prev,
        pageSize: pageSize,
        rows: data,
        rowCount: pagination.total_records,
        page: pagination.page_number,
      }));

      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getTasks(rowsState.pageSize, rowsState.page, companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      component="section"
      maxWidth="xl"
      sx={styles.dashCtn}
    >
      <Paper elevation={4}>
        <Grid container>
          <Grid item xs={12} sx={styles.wrapperBackHome}>
            <IconButton aria-label="delete">
              <HomeIcon
                onClick={() => {
                  history.push(ROUTENAME.dashboard);
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.emptyTableCell} />
                <TableCell sx={styles.tableHeadTitle}>Date</TableCell>
                <TableCell sx={styles.tableHeadTitle}>File</TableCell>
                <TableCell sx={styles.tableHeadTitle}>Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {React.Children.toArray(
                rowsState.rows &&
                  rowsState.rows.map((row) => <EmployeeFormRow row={row} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[20, 50, 100]}
          colSpan={3}
          count={rowsState.rowCount}
          rowsPerPage={rowsState.pageSize}
          page={rowsState.page - 1}
          onPageChange={(nextPage, value) => handlePageChange(value)}
          onRowsPerPageChange={(e) => handlePageSizeChange(e.target.value)}
          ActionsComponent={TablePaginationActions}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
        />
      </Paper>
    </Container>
  );
};

export default EmployeeForms;
