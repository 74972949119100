// @vendors
import { useEffect } from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// @style
import './App.css';

// @components
import ProtectedRoute from './components/guard/ProtectedRoute';
import NotFound from './components/NotFound.js';

// @routes
import Routes from './navigation/Route';

// @pages
import ForgotPassword from './pages/auth/ForgotPassword';
import Login from './pages/auth/LoginPage';
import ResetPassword from './pages/auth/ResetPassword';
import AboutUs from './pages/Home/AboutUs';
import Contacts from './pages/Home/Contacts';
import ErrorPage from './pages/Home/ErrorPage.js';
import Home from './pages/Home/Home';
import Services from './pages/Home/Services';
import Prescreening from './pages/Questionnaire/Prescreening';
import EmailRequest from './pages/Questionnaire/components/EmailRequest';
import ExternalEmployer from './pages/External-Employer/ExternalEmployer';
import UnsubscribeEmail from './pages/Employer/components/UnsubscribeEmailForEmployee/UnsubscribeEmail';
import QuestionForVeteran from './pages/Questionnaire/components/QuestionForVeteran.js';
import SavedVeteranQuestion from './pages/Questionnaire/components/SavedVeteranQuestion.js';
import UploadDd214 from './pages/Questionnaire/components/UploadDd214.js';
import Logout from './pages/auth/Logout.js';

// @state
import store from './redux/store.js';
import { addUser } from './redux/actions/user.js';
import { useUI } from './app/context/ui.js';

// @helpers
import AppHelper from './helpers/AppHelper.js';
import { redirectToRockerbox } from './helpers/FunctionsUtils.js';

// @services
import AuthServiceNewApi from './services/newApi/AuthServiceNewApi.js';

// @constants
import { ROUTENAME } from './navigation/RouteName.js';

function App() {
  const [time, setTime] = useState(null)
  const state = store.getState();
  const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID
  const CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET
  const { snackbarUI } = useUI();
  const authService = new AuthServiceNewApi()
  const dispatch = useDispatch()
  const location = window.location.href;
  const currentUrl = location.toString();

  useEffect(() => {
    redirectToRockerbox(currentUrl);
  }, [currentUrl])

  const refreshForToken = () => {
    const REFRESH_TOKEN = state?.user?.tokenNewApi?.refresh_token
    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('client_id', CLIENT_ID)
    params.append('client_secret', CLIENT_SECRET)
    params.append('refresh_token', REFRESH_TOKEN)
    refreshTokenNewApi(params)
  }

  const refreshTokenNewApi = async (params) => {
    try {
      const response = await authService.loginNewApi(params)
      const data = response?.data
      const expiryTime = updateTimeToExpire(data)
      let payload = { ...state?.user, tokenNewApi: data, expiryTime:expiryTime};
      dispatch(addUser(payload));
    } catch (error) {
      AppHelper.checkError(error, snackbarUI);
      dispatch({ type: 'LOGOUT' })
    }
  }

  const updateTimeToExpire = (data) => {
    const currentDate = new Date().getTime()
    const timeTokenMilisecons = data?.expires_in * 1000
    const expiryTime = currentDate + timeTokenMilisecons
    return expiryTime
  }

  const refreshToken = () => {
    const isTimeToRefresh = state?.user?.expiryTime
    const dateRefresh = new Date(isTimeToRefresh)
    const currentDate = new Date(time)
    if(dateRefresh && dateRefresh <= currentDate){
      refreshForToken()
    }
    setTimeout(() => {
      setTime(new Date().getTime())
    }, 60000);
  }

  useEffect(() => {
    return () => {
      if(state?.user?.hasNewToken) {
        refreshToken()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, state]) 

  return (
    <>
      <Router>
        <Switch>
          <Route exact path={ROUTENAME.mainRoute} render={() => <Login title='Login' />} />
          <Route exact path={ROUTENAME.home} render={() => <Home title='Home' />} />
          <Route exact path={ROUTENAME.aboutUs} render={() => <AboutUs title='About Us' />} />
          <Route exact path={ROUTENAME.services} render={() => <Services title='Services' />} />
          <Route exact path={ROUTENAME.contacts} render={() => <Contacts title='Contacts' />} />
          <Route exact path={ROUTENAME.login} render={() => <Login title='Login' />} />
          <Route
            exact
            path={ROUTENAME.recoverPassword}
            render={() => <ForgotPassword title='Recover Password' />}
          />
          <Route path={ROUTENAME.resetPassword} render={() => <ResetPassword title='Reset password' />} />
          <Route
            exact
            path='/quest/:hashToken'
            render={() => <Prescreening title='WOTC Questionnaire' />}
          />
          <Route
            exact
            path='/rockerbox-quest/:hashRb'
            render={() => <Prescreening title='WOTC Questionnaire' />}
          />
          <Route
            exact
            path={ROUTENAME.ob365}
            render={() => <EmailRequest />}
          />
          <Route
            exact
            path='/company/:hashToken/:token'
            render={() => <ExternalEmployer />}
          />
          <Route
            exact
            path='/quest/:hashToken/:employerId'
            render={() => <Prescreening title='WOTC Questionnaire' />}
          />
          <Route exact path='/subscribe-information/:id' render={() => <UnsubscribeEmail />} />
          <Route exact path='/upload-file-dd214/:hash' render={() => <QuestionForVeteran />} />
          <Route exact path='/upload-dd214/:hash' render={() => <UploadDd214 />} />
          <Route exact path='/saved-veteran-question/:hash' render={() => <SavedVeteranQuestion />} />
          <Route exact path={ROUTENAME.logout} render={() => <Logout/> } />
          {Routes.map((layout, i) => {
            return (
              <ProtectedRoute
                key={i}
                exact={layout.exact}
                path={layout.path}
                component={layout.component}
                name={layout.name}
                title={layout.pageTitle}
              />
            );
          })}
          <Route exact path={ROUTENAME.error} component={ErrorPage} />
          <Route path='*' render={() => <NotFound/> } />
        </Switch>
      </Router>
    </>
  );
}

export default App;
