// @vendors
import React, { useContext, useMemo } from 'react'
import { Box, TablePagination } from '@mui/material'

// @helpers
import {TablePaginationActions} from '../../../../helpers/TablePaginationAction';

// @constants
import { PayrollStyle } from '../../../../assets/css/payroll-style';
import { payrollContext } from '../../payrollView';

const Paginator = () => {
    const style = useMemo(() => PayrollStyle(), []);
    const {
        getRecordsList,
        pages,
        setPages,
        filterModal
    } = useContext(payrollContext);

    const handleChangePage = (event, newPage) => {
        const page = newPage === 0 ? 1 : newPage === pages.page ? newPage + 1 : newPage;
        setPages((prev) => ({
            ...prev,
            page: parseInt(page),
            size: pages.size
        }));
        const params = {page: page, size: pages.size, filters:filterModal};
        getRecordsList(params);
    };
    
    const handleChangeRowsPerPage = (event) => {
        const rows = event.target.value;
        setPages((prev) => ({
            ...prev,
            page: 1,
            size: rows
        }));
        const params = {page: 1, size: rows, filters:filterModal};
        getRecordsList(params);
    };

  return (
    <Box sx={style.contaierPagination}>
        <TablePagination
            component="div"
            rowsPerPageOptions={[10, 20, 50]}
            colSpan={3}
            count={pages.totalRows || 0}
            rowsPerPage={pages.size}
            page={pages.page < 0 || pages.page === 0 ? 0 : pages.page - 1}
            SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() => TablePaginationActions(pages, handleChangePage)}
        />
    </Box>
  )
}

export default Paginator