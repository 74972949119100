import moment from 'moment/moment';
import { styled } from "@mui/material/styles";
import { Tooltip, tooltipClasses } from '@mui/material';

import AppHelper from './AppHelper';
import { LISTROLES } from './ListRoles';

import OrganizationServiceNewApi from '../services/newApi/OrganizationService';
import AuthServiceNewApi from '../services/newApi/AuthServiceNewApi';

export const formDataUploadEmployees = (file) => {
  const formdata = new FormData();
  formdata.append('file', file);
  return formdata;
};

export const formattDate = (date) => {
  if (date) return moment(date).format('DD-MM-YYYY');
  return '';
};

export const validateEmptyString = (text) => {
  if (text === null) return '';
  else return text;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuItemProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export const targetGroupCode = [
  { id: 1, name: 'Bc' },
  { id: 2, name: 'Ba' },
  { id: 3, name: 'Ab' },
  { id: 4, name: 'Cd' },
  { id: 5, name: 'Ad' },
  { id: 6, name: 'Bb' }
];

export const convertDateTolocaleTime = (date) => {
  if(!date)return;
  return Intl.DateTimeFormat(navigator.language || navigator.userLanguage, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).format(new Date(`${date} ${date.includes(':') ? '' : '12:00:00'} UTC`));
};

export const convertDateToNewFormat = (date) => {
  const splitDate = date.split('/');
  const day = splitDate[0];
  const month = splitDate[1];
  const year = splitDate[2];
  return `${month}/${day}/${year}`
}

export const ob365En = {
  welcome: 'Welcome',
  wait: 'WAIT!',
  changeToEarn: 'Don’t miss your chance to earn $250!',
  congratulations: 'Congratulations on joining Empire Workforce!',
  completeOnboardingProcess: 'To complete your onboarding process, we kindly ask that you fill out the Work Opportunity Tax Credit (WOTC) screening questionnaire. Rest assured, your employment with us is secure—this questionnaire is simply a routine part of our onboarding process and will not impact your application status in any way.',
  responsesConfidential: "Your responses are confidential and will only be used to determine our eligibility for valuable tax credits that help us continue to grow and support our team. Additionally, by completing the WOTC questionnaire fully and accurately, you'll be automatically entered into our monthly drawing to win a $250 gift card!.",
  question: 'Please provide the email you used in your onboarding process.',
  programVoluntary: 'This program is voluntary, but we appreciate your willingness to complete the following survey.'
}

export const ob365Es = {
  welcome: 'BIENVENIDO',
  wait: '¡ESPERA!',
  changeToEarn: '¡No pierdas la oportunidad de ganar $250!',
  congratulations: '¡Felicitaciones por unirte a Empire Workforce!',
  completeOnboardingProcess: 'Para completar tu proceso de incorporación, te pedimos que completes el cuestionario de selección de Crédito fiscal por oportunidad de trabajo (WOTC). Ten la seguridad de que tu empleo con nosotros está asegurado: este cuestionario es simplemente una parte rutinaria de nuestro proceso de incorporación y no afectará el estado de tu solicitud de ninguna manera.',
  responsesConfidential: "Tus respuestas son confidenciales y solo se utilizarán para determinar nuestra elegibilidad para créditos fiscales valiosos que nos ayudan a seguir creciendo y apoyando a nuestro equipo. Además, al completar el cuestionario WOTC de forma completa y precisa, ¡ingresarás automáticamente en nuestro sorteo mensual para ganar una tarjeta de regalo de $250!",
  question: 'Proporcione el correo electrónico que utilizó en su proceso de incorporación.',
  programVoluntary: 'Este programa es voluntario, pero apreciamos su disposición a completar la siguiente encuesta.'
};

//download templete for update employers or create employees
export const downloadTemplate = async (data, name) => {
  let url = window.URL.createObjectURL(
    new Blob([data], { type: "text/csv" })
  );
  let a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
};

export const getOrganizations = async (snackbarUI, blockUI) => {
  const organizationService = new OrganizationServiceNewApi();
  try {
    blockUI.current.open(true);
    const response = await organizationService.getOrganization();
    blockUI.current.open(false);
    return response;
  } catch (error) {
    blockUI.current.open(false);
    AppHelper.checkError(error, snackbarUI);
  }
}

export const redirectToRockerbox = (currentUrl) => {
  //if(currentUrl?.includes('axiscare'))return window.location.href  = 'https://app.rockerbox.tech/login';
  if(currentUrl?.includes('axiscare')){
    if(currentUrl?.includes('stg'))return locationHref(currentUrl, 'rockerbox');
    return locationHref(currentUrl, 'app');
  }
  return;
}

const locationHref = (currentUrl, string) => {
  const url = currentUrl?.replace('axiscare', string);
  return window.location.replace(url);
  //return window.location.href  = url;
}

export const permissionsForAddEmployer = (role) => {
  if(role === LISTROLES.superAdmin)return true;
  if(role === LISTROLES.orgManager)return true;
  if(role === LISTROLES.orgReporter)return true;
  return false;
}

export const permissionsForEditEmployer = (role) => {
  if(role === LISTROLES.superAdmin)return true;
  if(role === LISTROLES.orgManager)return true;
  if(role === LISTROLES.orgReporter)return true;
  if(role === LISTROLES.employerManager)return true;
  return false;
}

export const permissionsForAddOrEditEmployees = (role) => {
  if(role === LISTROLES.superAdmin)return true;
  if(role === LISTROLES.orgManager)return true;
  if(role === LISTROLES.orgReporter)return true;
  if(role === LISTROLES.orgProcessor)return true;
  if(role === LISTROLES.employerManager)return true;
  if(role === LISTROLES.employerReporter)return true;
  return false;
}

export const permissionsForOrganization = (role) => {
  if(role === LISTROLES.orgManager ||
    role === LISTROLES.orgReporter ||
    role === LISTROLES.orgReader ||
    role === LISTROLES.orgProcessor
  )return true;
  return false;
}

export const getOrganizationWithoutToken = async (blockUI, snackbarUI) => {
  const authService = new AuthServiceNewApi();
  try {
    blockUI.current.open(true);
    const response = await authService.getOrganizations();
    const logo = await logoOrganization(response?.data?.data);
    return logo;
  } catch (error) {
    blockUI.current.open(false);
    AppHelper.checkError(error, snackbarUI);
  }
}

const logoOrganization = async (resp) => {
  const location = window.location.href;
  const currentUrl = location.toString();
  const stringToReplace = currentUrl.includes('login') ? '/login' : '/home';
  const url = currentUrl.replace(stringToReplace, '')
  const logo = resp?.find((item) => item?.homeUrl.includes(url))
  return logo?.logo ? logo?.logo : null;
}

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} key={Math.random()}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

export const getTargetGroup = (item, isCode) => {
  const groups = isCode ? item?.map((x) => x?.code).toString() : item?.map((x) => x?.targetGroup).toString();
  const addSpace = groups?.replaceAll(`,`,`, `);
  if(addSpace?.length > 40)return `${addSpace.substring(0, 40)}...`;
  return addSpace;
};

export const getTargetGroupTooltip = (item) => {
  if(!item?.length)return
  return item?.map((x) => <p>{x?.targetGroup}</p>);
}

export const stateStatusList = [
  {id: 1, stateStatus: "Pending"}, 
  {id: 2, stateStatus: "Yes"},
  {id: 3, stateStatus: "No"}
]
