// @vendors
import React, { memo, useMemo } from 'react';
import Grid from "@mui/material/Grid";

// @state
import { useUI } from '../../../app/context/ui';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';
import { MenuProps } from '../../../assets/css/tableComponents-styles';

// @materials
import {
  Box,
  Button,
  EditIcon,
  ExpandMoreRoundedIcon,
  Link,
  MenuItem,
  Select,
  Typography,
} from '../../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @services
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';

const AdminView = memo(({
  id,
  name,
  status,
  setEmployerDetails,
  displayName,
}) => {
  const { blockUI, snackbarUI } = useUI();
  const companyService = useMemo(() => new CompanyServiceNewApi(), []);
  const styles = useMemo(() => EmployerStyles(), []);
  let statusValues = ['active', 'suspended', 'inactive'];

  const onStatusChange = async (e, id) => {
    try {
      blockUI.current.open(true);
      await companyService.updateCompanyStatus(id, e.target.value);
      setEmployerDetails((prev) => ({ ...prev, status: e.target.value }));
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
        sx={styles.adminView}
      >
        <Grid item xs={12} sm={4} >
          <Box sx={styles.adminView}>
            <Typography>{displayName ? displayName : name || 'Company name'}</Typography>
            <Select
              value={status || ''}
              IconComponent={ExpandMoreRoundedIcon}
              onChange={(e) => onStatusChange(e, id)}
              sx={styles.statusSelect}
              MenuProps={MenuProps}
            >
              {React.Children.toArray(
                statusValues.map((st) => (
                  <MenuItem value={st} disabled={st === status} sx={{textTransform: 'capitalize'}}>
                    {st}
                  </MenuItem>
                ))
              )}
            </Select>
          </Box>
        </Grid>
        <Grid container item xs={12} sm={8} justifyContent="flex-end">
          <Box sx={styles.buttonsBox}>
            <Link href={`${ROUTENAME.editEmployer}${id}`} sx={styles.editEmployer}>
              <Button endIcon={<EditIcon/>}>Edit Employer Information</Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
});

export default AdminView;
