// @vendors
import React, { useContext, useMemo } from 'react';

// @components material
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../../../components/shared/MaterialUI";

// @components
import ToolbarChild from './ToolbarChild';
import { EmployeeContext } from '../../Employer';
import ChildrenRow from './ChildrenRow';

// @assets
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';

const ChildTable = () => {
  const styles = useMemo(() => EmployeeTableStyles(), []);
  const { listChildrens } = useContext(EmployeeContext);

  return (
    <>
      <Paper elevation={4}>
        <ToolbarChild/>
        <TableContainer sx={styles.tableContainer}>
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableRow sx={styles.tableRow}>
                <TableCell sx={styles.emptyTableCell} />

                <TableCell sx={{ ...styles.tableHeadTitle, textAlign: 'left' }}>
                  Company name
                </TableCell>

                <TableCell sx={{ ...styles.tableHeadTitle, }}>
                  Company Status
                </TableCell>
                
                <TableCell sx={styles.emptyTableCell} />
              </TableRow>
            </TableHead>

            <TableBody>
              {React.Children.toArray(
                listChildrens?.rows &&
                listChildrens?.rows?.map((row) => <ChildrenRow key={row?.id} row={row} /> )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default ChildTable