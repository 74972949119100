import React from 'react';

import {
  	Box,
  	useGridApiContext,
	Switch,
	Typography,
	Button,
} from '../../../../components/shared/MaterialUI.js';

export const CustomColumnsPanel = () => {
  	const apiRef = useGridApiContext();
	const { getAllColumns, setColumnVisibility, getVisibleColumns } = apiRef.current;
	const columnsVisibility = getVisibleColumns();
	const columns = getAllColumns().map((column) => ({field: column.field, name: column.headerName || 'Action Buttons', hide: column.hide}));

	const hideAllColumns = () => {

		if (columnsVisibility.length === 0) {
			return;
		}

		columns.forEach((column) => {
			setColumnVisibility(column.field, false);
		} );
	}

	const showAllColumns = () => {

		if (columnsVisibility.length === columns.length) {
			return;
		}

		columns.forEach((column) => {
			setColumnVisibility(column.field, true);
		} );
	}

  return (
    <>
			<Box 
				sx={{
					display: 'flex',
					flex: 1,
					flexDirection: {
						xs: 'column',
						xl: 'row',
					},
					gap: {
						xs: '2rem',
						xl: '0rem',
					},
					justifyContent: 'space-between',
					padding: {
						xs: '24px 0px',
						xl: '24px',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						gap: {
							xs: '8px',
							xl: '36px',
						},
					}}
				>
				{
					columns.map((column, index) => {
						return (
							<Box key={column.field} sx={{
								display: 'flex',
								alignItems: 'center',
							}}>
								<Switch
									onChange={() => {
										setColumnVisibility(column.field, column.hide);
									}}
									checked={!column.hide}
									color="success"
								/>
								<Typography>{column.name}</Typography>
							</Box>
						);
					}
					)
				}
				</Box>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-around',
						gap: '36px',
					}}
				>
					<Button
						onClick={hideAllColumns}
						>
						Hide All
					</Button>
					<Button
						onClick={showAllColumns}
						>
						Show All
					</Button>
				</Box>
				</Box>
    </>
  );
};
