/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import {
  keys as _keys,
  mapValues as _mapValues,
  omit as _omit,
  pick as _pick,
  toString as _toString,
  trim as _trim,
} from 'lodash';
import * as Yup from 'yup';
import sx from 'mui-sx';

// @state
import { useUI } from '../../../../app/context/ui';
import { CompanyContext } from '../CreateEmployer';

// @style
import { CreateEmployerStyles} from '../../../../assets/css/createEmployer-style';

// @material-ui
import {
  Button,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  HelpOutlined,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '../../../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../../../helpers/AppHelper';

// @services
import CompanyServiceNewApi from '../../../../services/newApi/CompanyServiceNewApi';
import PayrollService from '../../../../services/newApi/PayrollService';

const SecondForm = () => {
  const {
    handleStepDirection,
    onSubmit,
    id,
    state,
    dispatch,
    dataCompany,
    parentId
  } = useContext(CompanyContext);
  const { secondFormValues } = state;
  const companyService = new CompanyServiceNewApi();
  const userStyle = CreateEmployerStyles();
  const payrollService = new PayrollService();
  const [isMobile, setIsMobile] = useState(false);
  const { snackbarUI } = useUI();
  const [paySchedules, setPaySchedules] = useState([]);

  useEffect(() => {
    const data = _pick(dataCompany, _keys(secondFormValues));
    if(parentId && dataCompany?.id)dispatch({
      type: 'setSecondFormValue',
      payload: data,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  useEffect(() => {
    verifyScreenSize();
    window.addEventListener('resize', verifyScreenSize);
    return () => {
      window.removeEventListener('resize', verifyScreenSize);
    };
  }, []);

  useEffect(() => {
    getPayScheduleList();
    if (id) getEmployerDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    dispatch({
      type: 'handleSFInputs',
      payload: { [e.target.name]: e.target.value },
    });
  };

  const validationSchema = Yup.object().shape({
    payrollContactName: Yup.string().when(['payrollContactEmail'], {
      is: (payrollContactEmail) => payrollContactEmail,
      then: Yup.string('Enter your Payroll Contact Name').max(50, 'Max. 50 characters').required('payrollContactName is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    payrollContactEmail: Yup.string().when(['payrollContactName'], {
      is: (payrollContactName) => payrollContactName,
      then: Yup.string('Enter the Payroll Contact Email').email('Invalid email').max(50, 'Max. 50 characters').required('payrollContactEmail is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    poaContact: Yup.string().when(['poaEmail'], {
      is: (poaEmail) => poaEmail,
      then: Yup.string('Enter your poaContact').max(50, 'Max. 50 characters').required('poaContact is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    poaEmail: Yup.string().when(['poaContact'], {
      is: (poaContact) => poaContact,
      then: Yup.string('Enter your poaEmail').email('Invalid email').max(50, 'Max. 50 characters').required('poaEmail is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    wotcContact: Yup.string().when(['wotcEmail'], {
      is: (wotcEmail) => wotcEmail,
      then: Yup.string('Enter your WOTC Contact').max(50, 'Max. 50 characters').required('wotcContact is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    wotcEmail: Yup.string().when(['wotcContact'], {
      is: (wotcContact) => wotcContact,
      then: Yup.string('Enter your poaEmail').email('Invalid email').max(50, 'Max. 50 characters').required('wotcEmail is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    sponsor: Yup.string().when(['sponsorEmail'], {
      is: (sponsorEmail) => sponsorEmail,
      then: Yup.string('Enter your executiveSponsor').max(50, 'Max. 50 characters').required('Executive sponsor is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    sponsorEmail: Yup.string().when(['sponsor'], {
      is: (sponsor) => sponsor,
      then: Yup.string('Enter your poaEmail').email('Invalid email').max(50, 'Max. 50 characters').required('sponsorEmail is required').nullable(),
      otherwise: Yup.string().nullable()
    }),
    signatureResponsibleName8850Form: Yup.string().nullable().notRequired(),
    signatureResponsibleTitle8850Form: Yup.string().nullable().notRequired(),
    signatureResponsibleName9061Form: Yup.string().nullable().notRequired(),
    payScheduleId: Yup.string().nullable().required('paySchedule is required'),
    employerRate: Yup.string().nullable().notRequired().matches(/^(100|[0-9]{1,2}(\.[0-9]*)?)$/, 'The Rate must be whole numbers or decimals less than or equal to 100'),
  },
    [
      ['poaContact', 'poaEmail'],
      ['poaEmail', 'poaContact'],
      ['payrollContactName', 'payrollContactEmail'],
      ['payrollContactEmail', 'payrollContactName'],
      ['wotcContact', 'wotcEmail'],
      ['wotcEmail', 'wotcContact'],
      ['sponsor', 'sponsorEmail'],
      ['sponsorEmail', 'sponsor'],

  ]);

  const onFormSubmit = () => {
    let second = _mapValues(secondFormValues, function (value, key) {
      return _trim(_toString(value));
    });
    dispatch({
      type: 'setSecondFormValue',
      payload: second,
    });
    onSubmit();
  };

  const getEmployerDetails = async (id) => {
    try {
      const resp = await companyService.getCompanyById(id);
      const res1 = _pick(resp?.data?.data, _keys(secondFormValues));
      const data = _omit(res1, [
        'name',
        'tradeName',
        'fein',
        'address',
        'city',
        'state',
        'stateId',
        'county',
        'email',
        'zipCode'
      ]);
      dispatch({
        type: 'setSecondFormValue',
        payload: data,
      });
      //setRate(resp?.data?.data?.employerRate);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const getPayScheduleList = async () => {
    try {
      const response = await payrollService.getPaySchedule();
      setPaySchedules(response?.data?.data);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const verifyScreenSize = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  
  return (
    <Grid container sx={{padding: '0px 70px 70px 70px'}}>
      <Grid item xs={12} md={12} sx={{textAlign: 'center', marginTop: '110px', marginBottom: '30px'}}>
        <Typography variant='h3'>{id ? 'Edit employer' : 'Create new employer'}</Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Formik
          initialValues={secondFormValues}
          validationSchema={validationSchema}
          onSubmit={() => {
            onFormSubmit();
          }}
          enableReinitialize={true}
        >
          {(props) => {
            const { touched, errors, handleBlur } = props;
            return (
              <Form autoComplete='off' className='formCreateUser' noValidate>
                <Grid item md={12} sx={12}>
                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>Payroll Contact Name:</p>
                      <TextField
                        label='Type your payroll contact name here'
                        variant='outlined'
                        id='payrollContactName'
                        name='payrollContactName'
                        value={secondFormValues.payrollContactName || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.payrollContactName && Boolean(errors.payrollContactName)}
                        helperText={
                          errors.payrollContactName && touched.payrollContactName
                            ? errors.payrollContactName
                            : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <p>Payroll Account Email:</p>
                      <TextField
                        label='example@email.com'
                        variant='outlined'
                        id='payrollContactEmail'
                        name='payrollContactEmail'
                        value={secondFormValues.payrollContactEmail || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.payrollContactEmail && Boolean(errors.payrollContactEmail)}
                        helperText={
                          errors.payrollContactEmail && touched.payrollContactEmail
                            ? errors.payrollContactEmail
                            : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <Grid style={{ display: 'flex' }}>
                        <p>
                          PoA Contact:
                        </p>
                        <Tooltip style={{ marginLeft: '5vw', color: '#80BB57' }}
                          title={
                            <p style={{ fontSize: '1.3em' }}>
                              PoA Contact
                            </p>
                          }
                          placement='right'
                          arrow
                        >
                          <IconButton>
                            <HelpOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <TextField
                        label='Type your PoA contact here'
                        variant='outlined'
                        id='poaContact'
                        name='poaContact'
                        value={secondFormValues.poaContact || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.poaContact && Boolean(errors.poaContact)}
                        helperText={errors.poaContact && touched.poaContact ? errors.poaContact : ''}
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>PoA Account Email:</p>
                      <TextField
                        label='example@email.com'
                        variant='outlined'
                        id='poaEmail'
                        name='poaEmail'
                        value={secondFormValues.poaEmail || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.poaEmail && Boolean(errors.poaEmail)}
                        helperText={errors.poaEmail && touched.poaEmail ? errors.poaEmail : ''}
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <div style={{ display: 'flex' }}>
                        <p>
                          WOTC Contact:
                        </p>
                        <Tooltip style={{ marginLeft: '4vw', color: '#80BB57' }}
                          title={
                            <p style={{ fontSize: '1.3em' }}>
                              WOTC Contact
                            </p>
                          }
                          placement='right'
                          arrow
                        >
                          <IconButton>
                            <HelpOutlined />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <TextField
                        label='Type your WOTC contact here'
                        variant='outlined'
                        id='wotcContact'
                        name='wotcContact'
                        value={secondFormValues.wotcContact || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.wotcContact && Boolean(errors.wotcContact)}
                        helperText={errors.wotcContact && touched.wotcContact ? errors.wotcContact : ''}
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>WOTC Email:</p>
                      <TextField
                        label='example@email.com'
                        variant='outlined'
                        id='wotcEmail'
                        name='wotcEmail'
                        value={secondFormValues.wotcEmail || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.wotcEmail && Boolean(errors.wotcEmail)}
                        helperText={errors.wotcEmail && touched.wotcEmail ? errors.wotcEmail : ''}
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>Executive Sponsor:</p>
                      <TextField
                        label='Type your executive sponsor here'
                        variant='outlined'
                        id='sponsor'
                        name='sponsor'
                        value={secondFormValues.sponsor || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.sponsor && Boolean(errors.sponsor)}
                        helperText={errors.sponsor && touched.sponsor ? errors.sponsor : ''}
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>Executive Email:</p>
                      <TextField
                        label='example@email.com'
                        variant='outlined'
                        autoComplete='new-password'
                        id='sponsorEmail'
                        name='sponsorEmail'
                        type='sponsorEmail'
                        value={secondFormValues.sponsorEmail || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.sponsorEmail && Boolean(errors.sponsorEmail)}
                        helperText={
                          errors.sponsorEmail && touched.sponsorEmail ? errors.sponsorEmail : ''
                        }
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>Signature Responsible Name 8850 form:</p>
                      <TextField
                        label='Type your signature name here'
                        variant='outlined'
                        id='signatureResponsibleName8850Form'
                        name='signatureResponsibleName8850Form'
                        value={secondFormValues.signatureResponsibleName8850Form || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.signatureResponsibleName8850Form && Boolean(errors.signatureResponsibleName8850Form)}
                        helperText={
                          errors.signatureResponsibleName8850Form && touched.signatureResponsibleName8850Form
                            ? errors.signatureResponsibleName8850Form
                            : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <p>Signature Responsible Title 8850 form:</p>
                      <TextField
                        label='Type your signature title here'
                        variant='outlined'
                        id='signatureResponsibleTitle8850Form'
                        name='signatureResponsibleTitle8850Form'
                        value={secondFormValues.signatureResponsibleTitle8850Form || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.signatureResponsibleTitle8850Form && Boolean(errors.signatureResponsibleTitle8850Form)}
                        helperText={
                          errors.signatureResponsibleTitle8850Form && touched.signatureResponsibleTitle8850Form
                            ? errors.signatureResponsibleTitle8850Form
                            : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p style={{color: '#80BB57', fontWeight: 'bold'}}>Signature Responsible Name 9061 form:</p>
                      <TextField
                        label='Type your signature name here'
                        variant='outlined'
                        id='signatureResponsibleName9061Form'
                        name='signatureResponsibleName9061Form'
                        value={secondFormValues.signatureResponsibleName9061Form || ''}
                        onChange={(e) => handleChange(e)}
                        error={touched.signatureResponsibleName9061Form && Boolean(errors.signatureResponsibleName9061Form)}
                        helperText={
                          errors.signatureResponsibleName9061Form && touched.signatureResponsibleName9061Form
                            ? errors.signatureResponsibleName9061Form
                            : ''
                        }
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                      <p>Pay Schedules: <span style={{ color: 'red' }}>*</span></p>
                      <FormControl required fullWidth>
                          <InputLabel>Pay Schedules</InputLabel>
                          <Select
                            value={secondFormValues.payScheduleId || ''}
                            id='payScheduleId'
                            name='payScheduleId'
                            onChange={(e) => {handleChange(e)}}
                            onBlur={handleBlur}
                            sx={sx(userStyle.select, {
                              condition: Boolean(errors.payScheduleId && touched.payScheduleId),
                              sx: userStyle.selectError,
                            })}
                            label="Pay Schedules *"
                          >
                            {React.Children.toArray(
                              paySchedules && paySchedules?.map((item) => <MenuItem value={item.id}>{item.paySchedule}</MenuItem>)
                            )}
                          </Select>
                          {errors.payScheduleId && touched.payScheduleId && (
                              <Typography sx={userStyle.stateError}>{errors.payScheduleId}</Typography>
                            )}
                        </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <p>Employer Rate:</p>
                      <TextField
                        label="Type your RATE here"
                        variant="outlined"
                        id="employerRate"
                        name="employerRate"
                        value={secondFormValues.employerRate || ''}
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        error={touched.employerRate && Boolean(errors.employerRate)}
                        helperText={
                          errors.employerRate && touched.employerRate ? errors.employerRate : ''
                        }
                        disabled={false}
                        sx={userStyle.inputCustom}
                      />
                    </Grid>
                  </Grid>
                  
                  <Grid container spacing={(isMobile) ? 0 : 5}>
                    <Grid item xs={12} md={6}>
                      <Button 
                        color='inherit' 
                        onClick={(e) => handleStepDirection(e, 'back')}
                        sx={userStyle.formButtonWrapper}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button 
                        type='submit'
                        sx={userStyle.formButtonWrapper}
                        style={{backgroundColor: 'black'}}
                      >
                          {id ? 'Save employer' : 'Create employer'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default SecondForm;
