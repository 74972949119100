import { combineReducers } from 'redux';
import user from './user';
import editEmployeePayroll from './editPayrollReducer';
import employeeSelected from './employeeSelectedReducer';
import sortEmployeeReducer from './sortEmployeeReducer';
import organizationReducer from './organizationReducer';

const reducer = combineReducers({
  user,
  editEmployeePayroll,
  employeeSelected,
  sortEmployeeReducer,
  organizationReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return reducer(state, action);
};

export default rootReducer;