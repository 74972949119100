/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useContext, useEffect, useMemo, useState } from 'react'
import moment from 'moment';

// @material-ui
import {
    Box,
    Checkbox,
    DataGrid,
    FormControlLabel,
} from "../../../components/shared/MaterialUI";

// @style
import { TableInvoiceStyle } from '../../../assets/css/payroll-style';
import CustomNoRowsOverlay from '../../../assets/CustomNoRowsOverlay';
import { componentsStyles } from '../../../assets/css/tableComponents-styles';

// @components
import ConfirmationDialog from '../../../components/dialog/DialogConfirm';
import { payrollContext } from '../payrollView';

// @context
import { useUI } from '../../../app/context/ui';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @services
import PayrollService from '../../../services/newApi/PayrollService';

const InvoiceSumary = () => {
    const {dataInvoice, getRecordsList} = useContext(payrollContext);
    const styles = TableInvoiceStyle();
    const payrollService = useMemo(() =>  new PayrollService(), []);
    const [info, setInfo] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const { blockUI, snackbarUI } = useUI();
    const [itemToUpdate, setItemToUpdate] = useState();
    
    const handleCloseModal = (value) => {
        if(value) updateInvoiceSent(itemToUpdate);
        setOpenDialog(false);
    }

    const handleChange = (e, item) => {
        const params = {
            companyId: item?.companyId,
            year: item?.year,
            month: item?.month,
            invoice: e.target.checked ? true : false
        }
        setItemToUpdate(params);
        if(e.target.checked)return updateInvoiceSent(params)
        else setOpenDialog(true)
    }

    const updateInvoiceSent = async (params) => {
        try {
            blockUI.current.open(true);
            await payrollService.updateInvoiceStatus(params);
            getRecordsList();
            blockUI.current.open(false);
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }
    }

    const columns = [
        {
            field: 'updateAt',
            headerName:'Last Sync Date',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => (
                <Box sx={styles.employerLink}>
                  {moment(params.row.updateAt).format('MM-DD-YY')}
                </Box>
              ),
        },
        {
            field: 'employer',
            headerName: 'Employeer',
            disableColumnMenu: true,
            flex: 1
        },
        {
            field: 'totalInvoice',
            headerName: 'Invoice YTD ($)',
            disableColumnMenu: true,
            flex: 1
        },
        {
            field: 'currentInvoice',
            headerName: 'Actual Invoice ($)',
            disableColumnMenu: true,
            flex: 1
        },
        {
            field: 'monthDifference',
            headerName: 'Month Difference',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1
        },
        {
            field: 'currrentInvoiceUpdated',
            headerName: 'Updated Actual Invoice',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1
        },
        {
            field: 'isInvoiceSend',
            headerName: 'Invoice Sent',
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1,
            renderCell: (params) => (
                <FormControlLabel
                    checked={params?.row?.isInvoiceSend}
                    control={
                        <Checkbox 
                            onChange={(e) => handleChange(e, params.row)}
                            sx={{color: '#BAC7D5', '& .MuiSvgIcon-root': {fontSize: '24px'}}}
                        />}
                    label="Yes"
                    labelPlacement="end"
                />
            ),
        },
    ];
  
    useEffect(() => {
        setInfo(dataInvoice ?? [])
    }, [dataInvoice])
  
  return (
    <>
        <DataGrid
            columns={columns}
            rows={info}
            components={{ NoRowsOverlay: CustomNoRowsOverlay }}
            componentsProps={componentsStyles}
            headerHeight={80}
            rowHeight={70}
            disableSelectionOnClick
            checkboxSelection={false}
            sx={{...styles.tableGrid, minHeight: '700px'}}
            hideFooter={true}
            hideFooterPagination={true}
        />

        <ConfirmationDialog
            id="confirm-dialog"
            onClose={handleCloseModal}
            open={openDialog}
            view="payroll"
            message="Are you sure you want to Uncheck?"
        />
    </>
  )
}

export default InvoiceSumary
