// @vendors
import * as React from "react";
import { TablePagination } from "@mui/material";

// @components
import { DataTable } from "../../Admin";
import { TablePaginationActions } from "./TablePaginationActions";

export const CustomPagination = (props) => {
  const {
    rowsState,
    setRowsState,
    getAllCompanies,
  } = React.useContext(DataTable);

  const handleChangePage = (event, newPage) => {
    const page = newPage === 0 ? 1 : newPage === rowsState.currentPage ? newPage + 1 : newPage
    setRowsState((prev) => ({
      ...prev,
      currentPage: parseInt(page),
      pageSize:rowsState.pageSize
    }));
    const params = { page: page, size: parseInt(rowsState.pageSize)}
    getAllCompanies(params);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsState((prev) => ({
      ...prev,
      currentPage: 1,
      pageSize: event.target.value
    }));
    const params = {size: event.target.value}
    getAllCompanies(params);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[10,20, 50, 100]}
      colSpan={3}
      count={rowsState.rowCount || 0}
      rowsPerPage={parseInt(rowsState.pageSize)}
      page={rowsState.page}
      SelectProps={{
        inputProps: {
          'aria-label': 'rows per page',
        },
        native: true,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={() => TablePaginationActions(rowsState.rowCount, rowsState.currentPage, rowsState.pageSize, handleChangePage)}
    />
  );
};
