// @vendors
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// @assets
import { StatsStyles } from '../../../assets/css/statsBoxes-style';

// @components
import {
  AssignmentTurnedInOutlinedIcon,
  Button,
  Card,
  CardContent,
  Grid,
  PersonAddAlt1OutlinedIcon,
  PersonOutlineOutlinedIcon,
  PersonRemoveOutlinedIcon,
  Typography,
} from '../../../components/shared/MaterialUI';

// @constants
import { ROUTENAME } from '../../../navigation/RouteName';
import { CircularProgress } from '@mui/material';

export const StatsBoxes = ({stats}) => {
  const styles = useMemo(() => StatsStyles(), []);
  const history = useHistory();
  const location = useLocation();

  const activateFocusAdmin = () => {
    const input = document.getElementById('searchCompany')
    if(location?.pathname === ROUTENAME.dashboard)return input?.focus()
    else{
      history.push(ROUTENAME.dashboard)
      input?.focus()
    }
  }

  const activateFocusEmployee = () => {
    const input = document.getElementById('searchByEmployee')
    if(location?.pathname === ROUTENAME.searchEmployees)return input?.focus()
    else{
      history.push(ROUTENAME.searchEmployees)
      input?.focus()
    }
  }

  const renderStats = (value) => {
    if(Object.entries(stats).length === 0){
      return (
        <Typography>
          <CircularProgress color="inherit" size="20px"/>
        </Typography>
      )
    }
    return (<Typography sx={styles.statsData}>{value || '0'}</Typography>)
  }

  return (
    <Grid component={'section'} sx={styles.container}>
      <Card elevation={3} sx={styles.box}>
        <Grid sx={styles.gridCard}>
          <Grid sx={styles.gridCardText} >
            <PersonOutlineOutlinedIcon sx={styles.icon}/>
            <CardContent>
              <Typography sx={styles.statsTitle}>Number of employers</Typography>
              {renderStats(stats?.employers)}
            </CardContent>
          </Grid>
          <Button
            variant='outlined'
            size='large'
            sx={styles.buttonSearch}
            onClick={() => activateFocusAdmin()}
          >
            Search
          </Button>
        </Grid>
      </Card>

      <Card elevation={3} sx={styles.box}>
        <Grid sx={styles.gridCard}>
          <Grid sx={styles.gridCardText}>
            <PersonAddAlt1OutlinedIcon sx={styles.icon} />
            <CardContent>
              <Typography sx={styles.statsTitle}>Number of employees</Typography>
              {renderStats(stats?.employees)}
            </CardContent>
          </Grid>
          <Button
            variant='outlined'
            size='large'
            sx={styles.buttonSearch}
            onClick={() => activateFocusEmployee()}
          >
            Search
          </Button>
        </Grid>
      </Card>

      <Card elevation={3} sx={styles.box}>
        <AssignmentTurnedInOutlinedIcon sx={styles.icon} />
        <CardContent>
          <Typography sx={styles.statsTitle}>Completed questionnaires</Typography>
          {renderStats(stats?.questionnaireCompleted)}
        </CardContent>
      </Card>
      <Card elevation={3} sx={styles.box}>
        <PersonRemoveOutlinedIcon sx={styles.icon} />
        <CardContent>
          <Typography sx={styles.statsTitle}>Abandoned questionnaires</Typography>
          {renderStats(stats?.questionnaireAbandoned)}
        </CardContent>
      </Card>
    </Grid>
  );
};
