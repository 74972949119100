// @vendors
import React, { createContext, useEffect, useState } from 'react'
import { Container, Grid } from '@mui/material'

// @components
import Pageheader from '../../components/PageHeader'
import ManualTable from './Components/ManualTable';

// @context
import { useUI } from '../../app/context/ui';

// @assets
import { DashboardStyles } from '../../assets/css/dashboard-style';
import { OrganizationStyle } from '../../assets/css/employeeTable-style';

// @helpers
import AppHelper from '../../helpers/AppHelper';

// @services
import ManualService from '../../services/newApi/ManualService';

const Manuals = () => {
    const { blockUI, snackbarUI } = useUI();
    const styles = DashboardStyles();
    const manualService = new ManualService();
    const stylesOrganization = OrganizationStyle();
    const [data, setData] = useState();

    useEffect(() => {
        getListManuals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const getListManuals = async () => {
        try {
            blockUI.current.open(true);
            const response = await manualService.getManuals();
            setData(response?.data?.data);
            blockUI.current.open(false);
        } catch (error) {
            blockUI.current.open(false);
            AppHelper.checkError(error, snackbarUI);
        }
    }

    return (
        <Container
          component="section"
          maxWidth="xl"
          sx={styles.dashCtn}
        >
          <Container
            component="section"
            maxWidth="xl"
            sx={stylesOrganization.containerTitle}
          >
            <Pageheader
              title={'Manuals'}
              subtitle={'Welcome to Manual dashboard'}
            />
          </Container>
          <Grid>
              <ManualContext.Provider value={{data, getListManuals}}>
                <ManualTable/>
              </ManualContext.Provider>
          </Grid>
        </Container>
      )
}

export const ManualContext = createContext({}); 
export default Manuals