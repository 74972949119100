// @vendors
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// @state
import { useUI } from '../../../app/context/ui';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';
import logo from "../../../assets/images/logoDashboardPublic.svg";

// @material-ui
import {
  Box,
  Card,
  IconButton,
  Input,
  Paper,
  PhotoCamera,
} from '../../../components/shared/MaterialUI';

// @helpers
import AppHelper from '../../../helpers/AppHelper';

// @services
import CompanyServiceNewApi from '../../../services/newApi/CompanyServiceNewApi';
import { LISTROLES } from '../../../helpers/ListRoles';

const EmployerProfilePic = memo(({ id }) => {
  const user = useSelector((loadedState) => loadedState.user);
  const companyId = id ? id : user?.companies[0]?.id;
  const [companyLogo, setCompanyLogo] = useState(null);
  const { blockUI, snackbarUI } = useUI();
  const styles = useMemo(() => EmployerStyles(), []);
  const companyServiceNewApi = useMemo(() => new CompanyServiceNewApi(), []);

  const getLogo = async (id) => {
    try {
      const r1 = await companyServiceNewApi.getLogo(id);
      const avatar = r1?.data?.data?.logo;
      if(avatar?.startsWith('http'))return setCompanyLogo(avatar);
      return setCompanyLogo(null);
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const uploadFile = (e, id) => {
    const formdata = new FormData()
    formdata.append('logo', e.target.files[0])
    uploadImage(formdata, id)
  };

  const uploadImage = async (formdata, id) => {
    try {
      blockUI.current.open(true);
      const resp = await companyServiceNewApi.changeLogoToCompany(formdata, id);
      setCompanyLogo(resp.data?.data?.logo);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  useEffect(() => {
    getLogo(companyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const disableIconCamera = () => {
    if(user?.role === LISTROLES.orgReader || user?.role === LISTROLES.employerReader)return 'none'
    return 'auto'
  }

  return (
    <Card elevation={3} sx={styles.profilePicCtn}>
      <Paper elevation={4} sx={styles.profilePic}>
        {companyLogo ? (
          <img src={companyLogo ?? ''} alt='Company Logo' />
        ) : (
          <img src={logo ?? ''} alt='Rockerbox logo' />
        )}
        
        <Box sx={{...styles.uploadProfilePic, pointerEvents: disableIconCamera()}}>
          <label htmlFor='icon-button-file'>
            <Input
              accept='image/*'
              id='icon-button-file'
              type='file'
              sx={{ display: 'none' }}
              onChange={(e) => uploadFile(e, companyId)}
            />
            <IconButton
              aria-label='upload picture'
              component='span'
              sx={styles.uploadIcon}
            >
              <PhotoCamera />
            </IconButton>
          </label>
        </Box>
      </Paper>
    </Card>
  );
});

export default EmployerProfilePic;
