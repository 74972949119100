import AppServiceNewApi from "./AppServiceNewApi";

class ManualService extends AppServiceNewApi{
    constructor() {
        super();
        this.path = '/api/internal/v1/manuals';
    }

    getManuals(){
        return this.http.get(`${this.path}`);
    }

    addManual(body){
        return this.http.post(`${this.path}/upload-manual`, body);
    }
    
    deleteManual(manualId){
        return this.http.delete(`${this.path}/${manualId}`);
    }
}

export default ManualService;