// @vendors
import React, { useContext, useMemo, useState } from 'react';

// @material-ui
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box
} from "../../../components/shared/MaterialUI";

import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

// @assets
import { PayrollStyle } from '../../../assets/css/payroll-style';
import editIcon from '../../../assets/images/edit.svg';

// @components
import DialogNotes from '../Edit/DialogNotes';
import EditItemPayroll from '../Edit/EditItemPayroll';
import InvoiceSumary from './InvoiceSumary';
import PaginatorTable from './components/PaginatorTable';
import ToolbarPayroll from './components/ToolbarPayroll';
import PayrollRow from './components/PayrollRow';
import { payrollContext } from '../payrollView';
import { Divider } from '@mui/material';

const PayrollTable = () => {
  const style = useMemo(() => PayrollStyle(), []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [itemSelected, setItemSelected] = useState(null)
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const {
    currentTab,
    payrollList,
  } = useContext(payrollContext);

  const handleClickNotes = (event, item) => {
    setItemSelected(item)
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorEl(null);
    setItemSelected(null)
    setOpenModalEdit(false)
  };

  const handleOpenModalEdit = (item) => {
    setItemSelected(item)
    setOpenModalEdit(true)
  }

  return (
    <Paper elevation={4}>
      <ToolbarPayroll/>
      <TableContainer sx={style.tableContainer} component={Paper}>
        {
          currentTab === 1 || currentTab === 2
            ? <div style={style.divTableAndAction}>
                <div style={style.divTable}>
                  <Table aria-label="collapsible table" stickyHeader>
                    <TableHead sx={style.tableRow}>
                        <TableRow sx={style.tableRow}>
                            <TableCell sx={style.tableHeadTitle}>Last Sync Date</TableCell>
                            <TableCell sx={style.tableHeadTitle}>State Status</TableCell>
                            <TableCell sx={style.tableHeadTitle}>Employee Full Name</TableCell>      
                            <TableCell sx={style.tableHeadTitle}>Employeer</TableCell>        
                            <TableCell sx={style.tableHeadTitle}>Employer Rate (%)</TableCell>        
                            <TableCell sx={style.tableHeadTitle}>Target Group</TableCell>        
                            <TableCell sx={style.tableHeadTitle}>Target Group Code</TableCell>        
                            <TableCell sx={style.tableHeadTitle}>Start Date</TableCell>   
                            <TableCell sx={style.tableHeadTitle}>Pay Schedule</TableCell>
                            {currentTab === 2 &&<TableCell sx={style.tableHeadTitle}>Credit</TableCell>}
                            <TableCell sx={style.tableHeadTitle}>Current YTD Gross wages ($)</TableCell>
                            <TableCell sx={style.tableHeadTitle}>Current YTD Hours</TableCell>
                            <TableCell sx={style.tableHeadTitle}>WOTC Credit % Multiplier</TableCell>  
                            <TableCell sx={style.tableHeadTitle}>Max Wage ($)</TableCell>
                            <TableCell sx={style.tableHeadTitle}>Max Tax Credit ($)</TableCell>
                            <TableCell sx={style.tableHeadTitle}>Tax Credit YTD ($)</TableCell>
                            <TableCell sx={style.tableHeadTitle}>Invoice YTD ($)</TableCell>
                            <TableCell sx={style.tableHeadTitle}>Actual Invoice ($)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {payrollList?.data?.map((item, index) => (
                          <PayrollRow item={item} key={index}/>
                      ))}   
                    </TableBody>
                  </Table>
                </div>
                <div>
                  <Typography sx={style.tableHeadTitleAction} >Action</Typography>
                  <Divider/>
                  {payrollList?.data?.map((item,index) => (
                    <Box sx={style.containerIcons} key={index}>
                      <SpeakerNotesIcon
                        onClick={(e) => handleClickNotes(e, item)}
                        sx={style.iconMessageAndEdit}
                        />
                      <img
                        src={editIcon}
                        alt='Buttom for edit'
                        style={style.iconMessageAndEdit}
                        onClick={() => handleOpenModalEdit(item)}
                        />
                    </Box>
                  ))}
                </div>
              </div>
            : <InvoiceSumary />
        }

        {currentTab === 3 ? null : <PaginatorTable/>}
      </TableContainer>

      <DialogNotes
        anchorEl={anchorEl}
        handleClose={handleCloseModal}
        idItemSelected={itemSelected}
      />
      <EditItemPayroll
        open={openModalEdit}
        handleClose={handleCloseModal}
        data={itemSelected}
      />
    </Paper>
  )
}

export default PayrollTable
