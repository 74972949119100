// @vendors
import { useEffect } from "react";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

// @context
import { useUI } from "../../app/context/ui";

// @assets
import { ForgotPasswordStyles } from "../../assets/css/auth-style";
import logo from "../../assets/images/logoDashboardPublic.svg";

// @components material
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Link,
  TextField,
  Typography,
} from "../../components/shared/MaterialUI";

// @helpers
import AppHelper from "../../helpers/AppHelper";

// @services
import AuthService from "../../services/newApi/AuthServiceNewApi";

// @contants
import { ROUTENAME } from "../../navigation/RouteName";

const ForgotPassword = (props) => {
  const history = useHistory();
  const styles = ForgotPasswordStyles();
  const { blockUI, dialogUI, snackbarUI } = useUI();

  const authService = new AuthService();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Please enter a valid e-mail"),
  });

  const onSubmit = async (values) => {
    try {
      blockUI.current.open(true);
      const value = await authService.recoverPassword(values);
      const settings = {
        confirm: true,
        btn: {
          confirm: "OK",
        },
        onConfirm: () => {
          dialogUI.current.close();
          history.push(ROUTENAME.login);
        },
        onBackdropClick: () => {
          history.push(ROUTENAME.login);
        },
        styles: { ...styles.dialog },
        type: "logout",
      };
      dialogUI.current.open(null, value?.data?.message, settings);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    document.title = `RockerBox - ${props.title}`;
  });

  return (
    <>
      <Link href="/" sx={styles.logo}>
        <img src={logo} alt="dashboard public" />
      </Link>

      <Container maxWidth="100%" sx={styles.bgMain}>
        <Box sx={styles.forgotForm}>
          <CssBaseline />
          <Typography variant="h1">Forgot password</Typography>
          <Typography variant="h3">
            We will send you instructions to reset your password email.
          </Typography>
          <Box sx={styles.formMain}>
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={(values) => {
                onSubmit(values);
              }}
              validationSchema={ResetPasswordSchema}
            >
              {(props) => {
                const { values, handleChange } = props;
                return (
                  <Form className="form">
                    <Typography variant="h6">Type your e-mail:</Typography>
                    <TextField
                      margin="normal"
                      sx={styles.inputEmail}
                      required
                      fullWidth
                      name="email"
                      id="email"
                      autoComplete="email"
                      label="example@example.com"
                      value={values.email}
                      type="email"
                      variant="outlined"
                      onChange={handleChange}
                      placeholder="example@gmail.com"
                    />
                    <Box sx={styles.wrapperBtnSubmit}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={styles.btnSubmit}
                      >
                        Send
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default connect(null)(ForgotPassword);
