/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Form, Formik } from "formik";
import {cloneDeep as _cloneDeep, isEmpty as _isEmpty} from "lodash";
import sx from "mui-sx";
import { Link } from "react-router-dom";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { useSelector } from "react-redux";

// @context
import { useUI } from "../../../../../app/context/ui";
import { editEmployerReducer, initialState } from "../state";
import store from "../../../../../redux/store.js";

// @assets
import { EmployeeDetailStyles } from "../../../../../assets/css/employeeTable-style.js";
import {theme} from "../../../../../assets/theme";

// @components
import {
  AssignmentTurnedInIcon,
  Box,
  Button,
  ContentCopyRoundedIcon,
  FormControl,
  IconButton,
  InputLabel,
  LocalizationProvider,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  VisibilityOutlinedIcon,
  FormControlLabel,
  Grid,
  FormHelperText,
  Checkbox,
} from "../../../../../components/shared/MaterialUI.js";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

// @helpers
import AppHelper from "../../../../../helpers/AppHelper";
import { formatPhoneNumber } from "../../../../../helpers/FormatPhoneNumber";
import { formatSSN, getRawSSN } from "../../../../../helpers/FormatSSN";
import { validationSchema } from "../../helpers/validationSchema";

// @services
import HelpersServiceNewApi from "../../../../../services/newApi/HelpersServiceNewApi.js";
import OccupationServiceNewApi from "../../../../../services/newApi/OccupationServiceNewApi.js";
import EmployeeCompanyServiceNewApi from "../../../../../services/newApi/EmployeeCompanyServiceNewApi.js";

// @constants
import { convertDateTolocaleTime, permissionsForAddOrEditEmployees } from "../../../../../helpers/FunctionsUtils.js";
import { types } from "../../../../../redux/types/index.js";
import { ROUTENAME } from "../../../../../navigation/RouteName.js";

const EmployeeDetail = ({
  employeeInfo,
  pages,
  getAllEmployesByCompany,
  search,
  companyId
}) => {
  const [state, dispatch] = useReducer(editEmployerReducer, initialState);
  const states = state.geoLocation.states;
  const counties = state.geoLocation.counties;
  const cities = state.geoLocation.cities;
  const occupations = state.information.occupations;
  const getUser = store.getState()
  const isAdmin = getUser?.user?.isAdmin
  const { dialogUI, blockUI, snackbarUI } = useUI();
  const employeeServiceNewApi = useMemo(() => new EmployeeCompanyServiceNewApi(), []);
  const occupationService = useMemo(() => new OccupationServiceNewApi(), []);
  const statesService = useMemo(() => new HelpersServiceNewApi(), []);
  const styles = useMemo(() => EmployeeDetailStyles(), []);
  const [statusSendQuestionnaire, setStatusSendQuestionnaire] = useState(false);
  const [minDateStartedJob, setMinDateStartedJob] = useState(new Date().toDateString());
  const URL = window.location.origin;
  const [employeeValues, setEmployeeValues] = useState({
    firstName: employeeInfo?.firstName,
    lastName: employeeInfo?.lastName,
    middleName: employeeInfo?.middleName,
    phoneNumber: employeeInfo?.phoneNumber,
    address: employeeInfo?.address,
    state: employeeInfo?.state,
    county: employeeInfo?.county,
    city: employeeInfo?.city,
    zipCode: employeeInfo?.zipCode,
    socialNumber: employeeInfo?.socialNumber,
    dateBirth: employeeInfo?.dateOfBirth ?  employeeInfo.dateOfBirth : null,
    email: employeeInfo?.email,
    occupationCode: employeeInfo?.occupationCode,
    hourlyStartWage: employeeInfo?.hourlyStartWage?.toFixed(2),
    jobPosition: employeeInfo?.jobPosition,
    dateGaveInfo: employeeInfo?.dateGaveInfo ? employeeInfo.dateGaveInfo : null,
    dateStartedJob: employeeInfo?.dateStartedJob ?  employeeInfo.dateStartedJob : null,
    dateWasHired: employeeInfo?.dateWasHired ?  employeeInfo.dateWasHired : null,
    dateWasOfferedJob: employeeInfo?.dateWasOfferedJob ?  employeeInfo.dateWasOfferedJob : null,
    otherCounty: employeeInfo?.isManual ? employeeInfo?.county : null,
    otherCity: employeeInfo?.isManual ? employeeInfo?.city : null,
    externalEmployeeId: employeeInfo?.externalEmployeeId ? employeeInfo?.externalEmployeeId : null,
    subcription: employeeInfo?.subcription ? employeeInfo?.subcription : null,
    isManual: employeeInfo?.isManual ? employeeInfo?.isManual : null,
    summerYouthEmpZone: employeeInfo?.summerYouthEmpZone ? employeeInfo?.summerYouthEmpZone : null,
    stateCertification: employeeInfo?.stateCertification ? employeeInfo?.stateCertification : null,
    bullhornBranch: employeeInfo?.bullhornBranch ? employeeInfo?.bullhornBranch : null,
    isOtherCounty: employeeInfo?.isManual
  });
  const [hourlyWage, setHourlyWage] = useState();
  const validationSchemas = validationSchema(employeeValues?.isOtherCounty, isAdmin, minDateStartedJob);
  const user = useSelector((state) => state.user);
  const stateRef = useRef(null);

  useEffect(() => {
    if(!stateRef.current)getInitialInfo();
  }, []);

  const settings = {
    confirm: true,
    btn: {
      confirm: "Close",
      close: "",
    },
    onConfirm: () => {
      dialogUI.current.close();
    },
    styles: { ...styles.dialog },
  };

  const getInitialInfo = async () => {
    stateRef.current = true;
    try {
      const [r1, r2] = await Promise.all([
        statesService.getLocalities(),
        occupationService.getOccupation()
      ]);
      const [r3, r4] = await Promise.all([
        employeeInfo?.state ? statesService.getLocalities(employeeInfo?.state) : [],
        employeeInfo?.state ? statesService.getLocalities(employeeInfo?.state, employeeInfo.county) : []
      ]);
      assignDataToState(r1, r2, r3, r4);
    } catch (e) {
      AppHelper.check(e, snackbarUI);
    }
  };

  const assignDataToState = (r1, r2, r3, r4) => {
    const payload = {
      geoLocation: {
        states: r1?.data?.data,
        counties: r3?.data?.data,
        cities: r4?.data?.data,
      },
      information: {
        occupations: r2?.data?.data,
      },
    };
    dispatch({ type: "setInitialInformation", payload });
  }

  const getCounties = async (state, county) => {
    try {
      blockUI.current.open(true);
      const response = await statesService.getLocalities(state, county)
      blockUI.current.open(false);
      const data = response?.data?.data
      if(county)dispatch({ type: "setCities", payload: data });
      else dispatch({ type: "setCounties", payload: data });
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const copyLink = (e) => {
    navigator.clipboard
      .writeText(`${URL}/quest/${employeeInfo?.hash}`)
      .then(() => {
        snackbarUI.current.open("Copied to clipboard");
        setTimeout(() => {
          snackbarUI.current.close();
        }, 1500);
      })
      .catch((e) => {
        AppHelper.checkError(e, snackbarUI);
      });
  };

  const handleDateChange = (newValue, key) => {
    if (newValue === null) {
      setEmployeeValues({
        ...employeeValues,
        [key]: null
      });
      return
    }
    if (newValue?.toString() !== "Invalid Date") {
      setEmployeeValues({
        ...employeeValues,
        [key]: newValue
          ? newValue
          : employeeValues.dateBirth.toISOString().slice(0, -1),
      });
    }
  };

  const handleStateSelect = (e) => {
    const state = states?.find((s) => s.name === e)?.name;
    if (!state) return;
    getCounties(state);
    resetState(e);
  };

  const handleCountySelect = (e) => {
    const county = counties?.find((c) => c.name === e)?.name;
    const state = states.find((s) => s.name === employeeValues.state).name;
    getCounties(state, county);
    resetCounty(e);
  };

  const handleCitySelect = (e) => {
    setEmployeeValues({...employeeValues, city: e});
    getHourlyWage(e, 'city');
  };

  const handleOtherCitySelect = async (e) => {
    if(!employeeValues?.otherCity || !employeeValues?.otherCounty) return;
    if(e.length >= 2)return getHourlyWage(e, 'otherCity');
  };
  
  const getHourlyWage = async (e, field) => {
    const county = field === 'city' ? employeeValues?.county : employeeValues?.otherCounty;
    const resp = await statesService.getHourlyStartWage(employeeValues?.state, county, e)
    const data = resp?.data
    setHourlyWage(data?.toFixed(2))
  };

  const handleChange = (e) => {
    setEmployeeValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setEmployeeValues((prev) => ({
      ...prev,
      phoneNumber: formattedPhoneNumber,
    }));
  };

  const handleSSN = (e) => {
    const ssn = getRawSSN(e.target.value);
    const formatted = formatSSN(ssn);
    setEmployeeValues({ ...employeeValues, socialNumber: formatted });
  };

  const handleSendQuestionnaire = async (id) => {
    if (!employeeInfo?.status) {
      try {
        blockUI.current.open(true);
        const r1 = await employeeServiceNewApi.sendLink(id);
        getAllEmployesByCompany({page:pages?.currentPage, size:pages?.pageSize, filters:search})
        setTimeout(() => {
          blockUI.current.open(false);
          dialogUI.current.open("Great!", `${r1?.data?.data}`, settings);
        }, 500);
        setStatusSendQuestionnaire(true);
      } catch (e) {
        blockUI.current.open(false);
        AppHelper.checkError(e, snackbarUI);
      }
    } else {
      dialogUI.current.open(
        "Oops!",
        "The email with the link for this employee has already been sent",
        settings
      );
    }
  };

  const resetState = (e) => {
    dispatch({ type: "setCounties", payload: [] });
    dispatch({ type: "setCities", payload: [] });
    setEmployeeValues((prev) => ({
      ...prev,
      county: "",
      city: "",
      state: e,
      otherCounty: '',
      otherCity: '',
    }));
  };

  const resetCounty = (e) => {
    dispatch({ type: "setCities", payload: [] });
    setEmployeeValues((prev) => ({ ...prev, county: e, city: "" }));
  };

  const handleSubmit = async () => {
    let data = _cloneDeep(employeeValues);
    const payload = dataForUpdateEmployee(data)
    if (_isEmpty(payload)) {
      dialogUI.current.open(
        "Oops!",
        "You didn't provide data to update employee.",
        settings
      );
      return
    }
    submitEmployee(payload)
  };

  const submitEmployee = async (payload) => {
    try {
      blockUI.current.open(true);
      await employeeServiceNewApi.editEmployee(payload, companyId, employeeInfo?.id);
      successfulEmployeeUpdate()
      dispatch({
        type: types.ID_EMPLOYEE_SELECTED,
        detail: employeeInfo?.id
      })
      setHourlyWage()
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }

  const formattStartWage = (value) => {
    const stringValue = hourlyWage ? hourlyWage.toString() : value.toString()
    if(!stringValue.includes('.'))return `${stringValue}.00`
    if(stringValue.endsWith('.'))return `${stringValue}00`
    return stringValue
  }

  const dataForUpdateEmployee = (values) => {
    const DATA = {
      phoneNumber: values?.phoneNumber,
      firstName: values?.firstName,
      middleName: values?.middleName,
      lastName: values?.lastName,
      email: values?.email,
      address: values?.address,
      state: values?.state,
      city: values?.otherCity ? values?.otherCity : values?.city,
      county: values?.otherCounty ? values?.otherCounty : values?.county,
      zipCode: values?.zipCode,
      socialNumber: values?.socialNumber,
      dateOfBirth: values?.dateBirth ? moment(values.dateBirth).format('MM/DD/YYYY') : null,
      occupationCode: values?.occupationCode,
      dateGaveInfo: values?.dateGaveInfo ? moment(values.dateGaveInfo).format('MM/DD/YYYY') : null,
      dateWasOfferedJob: values?.dateWasOfferedJob ? moment(values.dateWasOfferedJob).format('MM/DD/YYYY') : null,
      dateWasHired: values?.dateWasHired ? moment(values.dateWasHired).format('MM/DD/YYYY') : null,
      dateStartedJob: values?.dateStartedJob ? moment(values.dateStartedJob).format('MM/DD/YYYY') : null,
      hourlyStartWage: formattStartWage(values?.hourlyStartWage),
      jobPosition: values?.jobPosition,
      subcription: values?.subcription,
      isManual: values?.isOtherCounty,
      summerYouthEmpZone: values?.summerYouthEmpZone,
      liveEz: values?.liveEz,
      stateCertification: employeeInfo?.id ? employeeInfo?.stateCertification : values?.stateCertification,
      externalEmployeeId: values?.externalEmployeeId,
      bullhornBranch: values?.bullhornBranch ? values?.bullhornBranch : ''
    }
    return DATA
  }

  const successfulEmployeeUpdate = () => {
    getAllEmployesByCompany()
    setTimeout(() => {
      blockUI.current.open(false);
      dialogUI.current.open(
        "Great!",
        "Employee has been updated successfully.",
        settings
      );
    }, 500);
  }

  const dowloadPdf = async (res, name) => {
    try {
      const response = await axios.get(res,{responseType: "blob", headers: {
        "Accept": "application/pdf",
      },});
      // Create a Blob from the response data
      const pdfBlob = new Blob([response?.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", name); 
      document.body.appendChild(tempLink);
      tempLink.click();
      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading PDF:", error);
    }
  };

  const verifyTypeFile = (res) => {
    let type = 'text/plain'
    let extention = ".docx"
    if(employeeInfo?.fileDd214?.includes('.pdf')){
      type= 'application/pdf'
      extention = '.pdf'
    }
    if(employeeInfo?.fileDd214?.includes('.png')){
      type= "image/png"
      extention = '.png'
    }
    if(employeeInfo?.fileDd214?.includes('.jpg')){
      type= "image/jpg"
      extention = '.jpg'
    }
    if(employeeInfo?.fileDd214?.includes('.jpeg')){
      type= "image/jpeg"
      extention = '.jpeg'
    }
    return {type, extention}
  }

  const dowloadPdfForVeteran = async (res) => {
    try {
      const typeFile = verifyTypeFile()
      const response = await axios.get(res,{responseType: "blob"});
      const pdfBlob = new Blob([response?.data], { type: typeFile.type });
      const url = window.URL.createObjectURL(pdfBlob);
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute("download", `${employeeInfo?.fullName}-DD214${typeFile.extention}`); 
      document.body.appendChild(tempLink);
      tempLink.click();
      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log("Error downloading PDF:", error);
    }
  };

  const isValidDateField = (date) => {
    const isValidDate = new Date(date).toDateString()
    if(isValidDate === 'Invalid Date')return false;
    return true;
  }

  useEffect(() => {
    if(employeeValues.dateWasHired || employeeValues.dateWasOfferedJob){
      if(isValidDateField(employeeValues.dateWasHired) && !employeeValues.dateWasOfferedJob)return setMinDateStartedJob(new Date(employeeValues.dateWasHired).toDateString())
      if(!employeeValues.dateWasHired && isValidDateField(employeeValues.dateWasOfferedJob))return setMinDateStartedJob(new Date(employeeValues.dateWasOfferedJob).toDateString())
      if(isValidDateField(employeeValues.dateWasHired) && isValidDateField(employeeValues.dateWasOfferedJob)){
        const dateWasHired = new Date(employeeValues.dateWasHired)
        const dateWasOfferedJob = new Date(employeeValues.dateWasOfferedJob)
        const value = dateWasHired >= dateWasOfferedJob ? dateWasOfferedJob : dateWasHired
        return setMinDateStartedJob(new Date(value).toDateString())
      }
    }else{
      return setMinDateStartedJob(new Date().toDateString())
    }
  }, [employeeValues.dateWasHired, employeeValues.dateWasOfferedJob, minDateStartedJob])

  const hasVeteranGroup = () => {
    const groupVeteran = employeeInfo?.subQualifie ? employeeInfo?.subQualifie?.split(',') : [];
    const spaceName = employeeInfo?.qualifie ? employeeInfo?.qualifie?.split(',') : [];
    const groups = spaceName?.concat(groupVeteran);
    return groups?.some(item => item?.includes('Veteran')) ? true : false;
  }

  const handleChangeCheck = (e) => {
    setEmployeeValues({
      ...employeeValues,
      isOtherCounty: e.target.checked,
      county: '',
      city: '',
      otherCounty: '',
      otherCity: ''
    })
  }

  const getValueWage = () => {
    if(hourlyWage && hourlyWage !== '0.00')return hourlyWage;
    if(employeeValues.hourlyStartWage && employeeValues.hourlyStartWage !== '0.00')return employeeValues.hourlyStartWage;
    return "";
  }
  
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={navigator.language}>
        <Box sx={styles.employeeDetails}>
          <Grid sx={styles.employeeDetailTitle}
          >
            <Typography variant="h6" sx={styles.employeeDetailTypographicBox}>Personal information</Typography>
            <Box sx={styles.employeeDetailTitleBox}
            >
              <Typography variant="body1" sx={styles.employeeDetailTypographicBox}>Created at: </Typography>
              <Typography variant="body1" sx={styles.employeeDetailTypographicBox}>{convertDateTolocaleTime(employeeInfo?.createdAt)}</Typography>
            </Box>
          </Grid>
          <Formik
            initialValues={employeeValues}
            validationSchema={validationSchemas}
            onSubmit={() => {handleSubmit()}}
            enableReinitialize={true}
          >
            {(props) => {
              const {touched, errors, handleBlur} = props;
              return (
                <Form autoComplete="off" className="formCreateUser">
                  <Box sx={styles.inputsCtn}>
                    <Grid
                      container
                      //item xs={12}
                      alignItems="center"
                      spacing={2}
                      justify="center"
                      sx={styles.detailFields}
                    >

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="First name"
                          id="firstName"
                          name="firstName"
                          value={employeeValues.firstName || ""}
                          onChange={(e) => handleChange(e)}
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                          onBlur={handleBlur}
                          error={touched.firstName && Boolean(errors.firstName)}
                          helperText={
                            errors.firstName ||
                            (errors.firstName && touched.firstName)
                              ? errors.firstName
                              : ""
                          }
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Last name"
                          id="lastName"
                          name="lastName"
                          value={employeeValues.lastName || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.lastName && Boolean(errors.lastName)}
                          helperText={
                            errors.lastName ||
                            (errors.lastName && touched.lastName)
                              ? errors.lastName
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Middle name"
                          id="middleName"
                          name="middleName"
                          value={employeeValues.middleName || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.middleName && Boolean(errors.middleName)}
                          helperText={
                            errors.middleName ||
                            (errors.middleName && touched.middleName)
                              ? errors.middleName
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Phone number"
                          id="phoneNumber"
                          name="phoneNumber"
                          value={employeeValues.phoneNumber || ""}
                          onChange={(e) => handlePhoneChange(e)}
                          onBlur={handleBlur}
                          error={
                            touched.phoneNumber && Boolean(errors.phoneNumber)
                          }
                          helperText={
                            errors.phoneNumber ||
                            (errors.phoneNumber && touched.phoneNumber)
                              ? errors.phoneNumber
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Address"
                          id="address"
                          name="address"
                          value={employeeValues.address || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.address && Boolean(errors.address)}
                          helperText={
                            errors.address || (errors.address && touched.address)
                              ? errors.address
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <FormControl fullWidth>
                          <InputLabel id="selectState">State</InputLabel>
                          <Select
                            id="state"
                            name="state"
                            label="State"
                            value={employeeValues.state}
                            disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                            onChange={(e) => {
                              handleStateSelect(e.target.value);
                            }}
                            onBlur={handleBlur}
                            sx={sx(styles.select, {
                              condition: Boolean(errors.state),
                              sx: styles.selectError,
                            })}
                          >
                            {states.length === 0 ? (
                              <MenuItem
                                value={employeeValues.state}
                                defaultValue
                                disabled
                                hidden
                              >
                                {employeeValues.state}
                              </MenuItem>
                            ) : (
                              states.map((state) => (
                                <MenuItem
                                  key={state.name}
                                  value={state.name}
                                >
                                  {state.name}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                          <Box sx={{display: 'flex', justifyContent: 'start'}}>
                          <FormControlLabel
                            id="isOtherCounty"
                            name="isOtherCounty"
                            checked={employeeValues?.isOtherCounty}
                            disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                            control={
                              <Checkbox
                                sx={{margin: '0px'}}
                                color="success"
                                onChange={(e) => handleChangeCheck(e)} 
                              />
                            }
                            label='Add county and city manually'
                            labelPlacement="end"
                          />
                          </Box>
                      </Grid>

                      {!employeeValues.isOtherCounty
                        ? <>
                              <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                                <FormControl fullWidth>
                                  <InputLabel id="selectCounty">County</InputLabel>
                                  <Select
                                    id="county"
                                    name="county"
                                    label="County"
                                    disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                                    value={employeeValues.county}
                                    onChange={(e) => {
                                      handleCountySelect(e.target.value);
                                    }}
                                    onBlur={handleBlur}
                                    sx={sx(styles.select, {
                                      condition: Boolean(errors.county),
                                      sx: styles.selectError,
                                    })}
                                  >
                                    {counties?.length === 0 ? (
                                      <MenuItem
                                        value={employeeValues.county}
                                        defaultValue
                                        disabled
                                        hidden
                                      >
                                        {employeeValues?.county}
                                      </MenuItem>
                                    ) : (
                                      counties?.map((county) => (
                                        <MenuItem
                                          key={county.name}
                                          value={county.name}
                                        >
                                          {county.name}
                                        </MenuItem>
                                      ))
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                                <FormControl fullWidth>
                                  <InputLabel id="selectCity">City</InputLabel>
                                  <Select
                                    id="city"
                                    name="city"
                                    label="City"
                                    disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                                    value={employeeValues.city}
                                    onChange={(e) => { handleCitySelect(e.target.value)}}
                                    onBlur={handleBlur}
                                    sx={sx(styles.select, {
                                      condition: Boolean(errors.city),
                                      sx: styles.selectError,
                                    })}
                                  >
                                    {cities?.length === 0 ? (
                                      <MenuItem
                                        value={employeeValues.city}
                                        defaultValue
                                        disabled
                                        hidden
                                      >
                                        {employeeValues.city}
                                      </MenuItem>
                                    ) : (
                                      cities?.map((city) => (
                                        <MenuItem key={city.name} value={city.name}>
                                          {city.name}
                                        </MenuItem>
                                      ))
                                    )}
                                  </Select>
                                </FormControl>
                              </Grid>
                          </>
                        : null
                      }

                      {employeeValues.isOtherCounty
                        ? <>
                            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                              <TextField
                                label="County"
                                id="otherCounty"
                                name="otherCounty"
                                value={employeeValues.otherCounty || ""}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleOtherCitySelect(employeeValues?.otherCity);
                                }}
                                onBlur={handleBlur}
                                error={touched.otherCounty && Boolean(errors.otherCounty)}
                                helperText={
                                  errors.otherCounty ||
                                  (errors.otherCounty && touched.otherCounty)
                                    ? errors.otherCounty
                                    : ""
                                }
                                fullWidth
                                disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                              />
                            </Grid>

                            <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                              <TextField
                                label="City"
                                id="otherCity"
                                name="otherCity"
                                value={employeeValues.otherCity || ""}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleOtherCitySelect(e.target.value);
                                }}
                                onBlur={handleBlur}
                                error={touched.otherCity && Boolean(errors.otherCity)}
                                helperText={
                                  errors.otherCity ||
                                  (errors.otherCity && touched.otherCity)
                                    ? errors.otherCity
                                    : ""
                                }
                                fullWidth
                                disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                              />
                            </Grid>
                          </>
                        : null
                      }

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Zip code"
                          id="zipCode"
                          name="zipCode"
                          value={employeeValues.zipCode || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.zipCode && Boolean(errors.zipCode)}
                          helperText={
                            errors.zipCode || (errors.zipCode && touched.zipCode)
                              ? errors.zipCode
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          placeholder="111-11-1111"
                          label="Social Security Number"
                          id="socialNumber"
                          name="socialNumber"
                          value={employeeValues.socialNumber || ""}
                          onChange={(e) => handleSSN(e)}
                          onBlur={handleBlur}
                          error={
                            touched.socialNumber && Boolean(errors.socialNumber)
                          }
                          helperText={
                            errors.socialNumber ||
                            (errors.socialNumber && touched.socialNumber)
                              ? errors.socialNumber
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <DesktopDatePicker
                          label="Date of Birth"
                          value={employeeValues.dateBirth}
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                          onChange={(date) => {
                            handleDateChange(date, "dateBirth");
                          }}
                          allowSameDateSelection={true}
                          renderInput={(params) => (
                            <TextField
                            {...params}
                              sx={styles.input}
                              id="dateBirth"
                              name="dateBirth"
                              value={employeeValues.dateBirth}
                              onBlur={handleBlur}
                              error={
                                touched.dateBirth && Boolean(errors.dateBirth)
                              }
                              helperText={
                                errors.dateBirth ||
                                (errors.dateBirth && touched.dateBirth)
                                  ? errors.dateBirth
                                  : ""
                              }
                              fullWidth
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Email"
                          id="email"
                          name="email"
                          value={employeeValues.email || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={Boolean(errors.email)}
                          helperText={
                            errors.email || (errors.email && touched.email)
                              ? errors.email
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <FormControl fullWidth >
                          <InputLabel id="selectOccupation">
                            Occupation
                          </InputLabel>
                          <Select
                            id="occupationCode"
                            name="occupationCode"
                            label="occupationCode"
                            disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                            value={employeeValues?.occupationCode || "none"}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            sx={sx(styles.select, {
                              condition: Boolean(errors.occupationCode),
                              sx: styles.selectError,
                            })}
                            fullWidth
                          >
                            <MenuItem value="none" defaultValue disabled hidden>
                              Please select a occupation
                            </MenuItem>
                            {occupations?.length === 0 ? (
                              <MenuItem
                                value={employeeValues?.occupationCode}
                                defaultValue
                                disabled
                                hidden
                              >
                                {employeeValues?.occupationCode}
                              </MenuItem>
                            ) : (
                              occupations?.map((occupation) => (
                                <MenuItem
                                  key={occupation.code}
                                  value={occupation.code}
                                >
                                  {occupation.occupation}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </FormControl>
                        <FormHelperText style={{color: '#f44336', paddingLeft: 14}}>
                          {isAdmin ? null : errors.occupationCode || (errors.occupationCode && touched.occupationCode)}
                        </FormHelperText>
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Hourly Start Wage"
                          id="hourlyStartWage"
                          name="hourlyStartWage"
                          value={getValueWage()}
                          onChange={(e) => {
                            setHourlyWage()
                            const re = /^\d{0,4}(?:\.\d{0,2})?$/;
                            if (e.target.value === "" || re.test(e.target.value))
                              handleChange(e);
                          }}
                          onBlur={handleBlur}
                          error={isAdmin ? null : touched.hourlyStartWage && Boolean(errors.hourlyStartWage)
                          }
                          helperText={isAdmin ? null : (errors.hourlyStartWage || (errors.hourlyStartWage && touched.hourlyStartWage)
                              ? errors.hourlyStartWage
                              : "")
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Job position"
                          id="jobPosition"
                          name="jobPosition"
                          value={employeeValues.jobPosition || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={isAdmin ? null : touched.jobPosition && Boolean(errors.jobPosition)}
                          helperText={isAdmin ? null : (errors.jobPosition || (errors.jobPosition && touched.jobPosition)
                              ? errors.jobPosition
                              : "")
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                          <DesktopDatePicker
                            label="Date information received"
                            value={employeeValues.dateGaveInfo}
                            //disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                            disabled={true}
                            onChange={(date) => {
                              handleDateChange(date, "dateGaveInfo");
                            }}
                            allowSameDateSelection={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={styles.input}
                                id="dateGaveInfo"
                                name="dateGaveInfo"
                                disabled={true}
                                value={employeeValues.dateGaveInfo}
                                onBlur={handleBlur}
                                error={touched.dateGaveInfo && Boolean(errors.dateGaveInfo)}
                                helperText={(errors.dateGaveInfo || (errors.dateGaveInfo && touched.dateGaveInfo)
                                    ? errors.dateGaveInfo
                                    : "")
                                }
                                fullWidth
                              />
                            )}
                          />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <DesktopDatePicker
                          label="First day of work"
                          value={employeeValues.dateStartedJob}
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                          onChange={(date) => {
                            handleDateChange(date, "dateStartedJob");
                          }}
                          minDate={minDateStartedJob}
                          allowSameDateSelection={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={styles.input}
                              id="dateStartedJob"
                              name="dateStartedJob"
                              value={employeeValues.dateStartedJob}
                              onBlur={handleBlur}
                              error={
                                touched.dateStartedJob &&
                                Boolean(errors.dateStartedJob)
                              }
                              helperText={
                                errors.dateStartedJob ||
                                (errors.dateStartedJob && touched.dateStartedJob)
                                  ? errors.dateStartedJob
                                  : ""
                              }
                              fullWidth
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <DesktopDatePicker
                          label="Date hired"
                          value={employeeValues.dateWasHired}
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                          onChange={(date) => {
                            handleDateChange(date, "dateWasHired");
                          }}
                          allowSameDateSelection={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={styles.input}
                              id="dateWasHired"
                              name="dateWasHired"
                              value={employeeValues.dateWasHired}
                              onBlur={handleBlur}
                              error={
                                touched.dateWasHired &&
                                Boolean(errors.dateWasHired)
                              }
                              helperText={
                                errors.dateWasHired ||
                                (errors.dateWasHired && touched.dateWasHired)
                                  ? errors.dateWasHired
                                  : ""
                              }
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      
                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <DesktopDatePicker
                          label="Date job was offered"
                          value={employeeValues.dateWasOfferedJob}
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                          onChange={(date) => {
                            handleDateChange(date, "dateWasOfferedJob");
                          }}
                          allowSameDateSelection={true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={styles.input}
                              id="dateWasOfferedJob"
                              name="dateWasOfferedJob"
                              value={employeeValues.dateWasOfferedJob}
                              onBlur={handleBlur}
                              error={
                                touched.dateWasOfferedJob &&
                                Boolean(errors.dateWasOfferedJob)
                              }
                              helperText={
                                errors.dateWasOfferedJob ||
                                (errors.dateWasOfferedJob &&
                                  touched.dateWasOfferedJob)
                                  ? errors.dateWasOfferedJob
                                  : ""
                              }
                              fullWidth
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="External employee id"
                          id="externalEmployeeId"
                          name="externalEmployeeId"
                          value={employeeValues.externalEmployeeId || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.externalEmployeeId && Boolean(errors.externalEmployeeId)}
                          helperText={
                            errors.externalEmployeeId ||
                            (errors.externalEmployeeId && touched.externalEmployeeId)
                              ? errors.externalEmployeeId
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                        <TextField
                          label="Branch"
                          id="bullhornBranch"
                          name="bullhornBranch"
                          value={employeeValues.bullhornBranch || ""}
                          onChange={(e) => handleChange(e)}
                          onBlur={handleBlur}
                          error={touched.bullhornBranch && Boolean(errors.bullhornBranch)}
                          helperText={
                            errors.bullhornBranch ||
                            (errors.bullhornBranch && touched.bullhornBranch)
                              ? errors.bullhornBranch
                              : ""
                          }
                          fullWidth
                          disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                        />
                      </Grid>

                      {employeeInfo?.form8850 &&
                        <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                          <Button
                            style={{width: '100%'}}
                            sx={styles.downloadButton}
                            onClick={() => dowloadPdf(employeeInfo?.form8850, 'Form_8850.pdf')}
                            disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                          >
                            Download 8850 form
                          </Button>
                        </Grid>
                      }

                      {employeeInfo?.form9061 &&
                        <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                          <Button
                            style={{width: '100%'}}
                            sx={styles.downloadButton}
                            onClick={() => dowloadPdf(employeeInfo?.form9061, 'Form_9061.pdf')}
                            disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                          >
                            Download 9061 form
                          </Button>
                        </Grid>
                      }

                      {hasVeteranGroup() &&
                        <Grid item xs={12} sm={6} md={5} lg={3} xl={3}>
                          {employeeInfo?.fileDd214
                            ? <Button
                                style={{width: '100%'}}
                                sx={styles.downloadButton}
                                onClick={() => dowloadPdfForVeteran(employeeInfo?.fileDd214)}
                                disabled={permissionsForAddOrEditEmployees(user?.role) ? false : true}
                              >
                                Download DD214
                              </Button>
                            : (employeeInfo?.statusFileDd214 === "HAVE IT BUT NOT NOW"
                                ? <Typography
                                    sx={{
                                      display: 'flex',
                                      color: '#000',
                                      fontSize: '18px'
                                    }}
                                  >
                                    DD214 not uploaded
                                  </Typography>
                                : <Typography
                                    sx={{
                                      display: 'flex',
                                      color: '#000',
                                      fontSize: '18px'
                                    }}
                                  >
                                    No DD214 to upload
                                  </Typography>
                              )
                          }
                        </Grid>
                      }
                    </Grid>
                  </Box>

                  {permissionsForAddOrEditEmployees(user?.role)
                    ? <>
                        <Box sx={styles.linkBox}>
                          <Grid container alignItems="center" spacing={2} justifyContent="space-between" sx={{ marginTop: "20px"}}>
                            <Grid container item sm={2} justifyContent="flex-end">
                              <Typography>Employee link</Typography>
                            </Grid>
                            <Grid container item sm={7} md={5} >
                              <TextField
                                value={`${URL}/quest/${employeeInfo?.hash}`}
                                id="formLink"
                                disabled
                                fullWidth
                              />
                            </Grid>
                            <Grid item sx={12} sm={3} md={5}>
                              <Tooltip
                                title={
                                  <p style={{fontSize: "16px", margin: "0"}}>
                                    Copy To Clipboard
                                  </p>
                                }
                                arrow
                              >
                                <IconButton onClick={() => copyLink()}>
                                  <ContentCopyRoundedIcon/>
                                </IconButton>
                              </Tooltip>
                              <Link
                                target="_blank"
                                to={{pathname: `${ROUTENAME.results}${employeeInfo?.id}`}}
                              >
                                <Tooltip
                                  title={
                                    <p style={{fontSize: "16px", margin: "0"}}>
                                      View Results
                                    </p>
                                  }
                                  arrow
                                >
                                  <IconButton>
                                    <VisibilityOutlinedIcon/>
                                  </IconButton>
                                </Tooltip>
                              </Link>
                              {employeeInfo?.status === "processed" ? (
                                <Button>
                                  <Tooltip
                                    title={
                                      <p style={{fontSize: "16px", margin: "0"}}>
                                        Cannot edit a processed questionnaire
                                      </p>
                                    }
                                    arrow
                                  >
                                    <IconButton>
                                      <AssignmentTurnedInIcon/>
                                    </IconButton>
                                  </Tooltip>
                                </Button>
                              ) : (!employeeInfo?.status || employeeInfo?.status === 'Sent' ? null :
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={{
                                    pathname: `/quest/${employeeInfo?.hash}/${employeeInfo?.companyId}`,
                                    state: {companyId: employeeInfo?.companyId},
                                  }}
                                >
                                  <Tooltip
                                    title={
                                      <p style={{fontSize: "16px", margin: "0"}}>
                                        Edit Answers
                                      </p>
                                    }
                                    arrow
                                  >
                                    <IconButton>
                                      <AssignmentTurnedInIcon/>
                                    </IconButton>
                                  </Tooltip>
                                </Link>
                              )}

                              <Button
                                sx={styles.sendButton}
                                onClick={() => handleSendQuestionnaire(employeeInfo?.id)}
                              >
                                {
                                  employeeInfo?.status === null
                                    ? <>Send</>
                                    : statusSendQuestionnaire
                                      ? <>RE-SEND</>
                                      : <>SEND</>
                                }
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box sx={{textAlign: "center", [theme.breakpoints.down('md')]: {padding: '0 5px', width: "100%",}}}>
                          <Button type="submit" sx={styles.saveChangesBtn}>
                            Save changes
                          </Button>
                        </Box>
                      </>
                    : <Grid sx={{width: '100%', height: '40px'}}></Grid>
                  }
                </Form>
              );
            }}
          </Formik>
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default EmployeeDetail;
