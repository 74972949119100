// @vendors
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// @assets
import { HomeStyles } from '../../assets/css/home-style';
import logo from '../../assets/images/logoDashboardPublic.svg';

// @components material
import {
  Box,
  Container,
  Grid,
  Link,
  PlayCircleFilled,
  Typography,
} from '../../components/shared/MaterialUI';

// @helpers
//import { getOrganizationWithoutToken } from '../../helpers/FunctionsUtils';

// @context
import { ROUTENAME } from '../../navigation/RouteName';

const Home = (props) => {
  const styles = HomeStyles();
  const [currentLogo, setCurrentLogo] = useState();
  const history = useHistory();

  useEffect(() => {
    document.title = `RockerBox - ${props.title}`;
    history.push(ROUTENAME.login)
  });

  /* const logoOrganization = async () => {
    const currentLogo = await getOrganizationWithoutToken(blockUI, snackbarUI);
    setCurrentLogo(currentLogo ? currentLogo : logo)
    setTimeout(() => {
      blockUI.current.open(false);
    }, 1000);
  } */

  useEffect(() => {
    //logoOrganization();
    setCurrentLogo(logo)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid container columns={20} height='100vh'>
        <Grid 
          item 
          xs={11} 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center"
          gap={4}
        >
          <Box sx={styles.logo_container} >
            {currentLogo &&
              <img
                src={currentLogo}
                alt='logo'
                style={{
                  objectFit: 'contain',
                  height: '100px',
                  borderRadius: '3px'
                }}
              />
            }
          </Box>
     
          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_title}>CLIENT</Typography>
            <Typography variant="h1" sx={styles.option_subtitle}>Payroll Login</Typography>
            <Link color="#607371">
            <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_title}>CPA</Typography>
            <Typography variant="h1" sx={styles.option_subtitle}>Payroll Login</Typography>
            <Link color="#607371">
             <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_title}>EMPLOYEE</Typography>
            <Typography variant="h1" sx={styles.option_subtitle}>Payroll Login</Typography>
            <Link color="#607371">
            <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="baseline" gap={2} sx={styles.option_container}>
            <Typography variant="h1" sx={styles.option_subtitle}>WOTC Login</Typography>
            <Link color="#607371" href={ROUTENAME.login}>
            <PlayCircleFilled sx={styles.option_icon}/>
            </Link>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Container component='div' sx={styles.home_cover} />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
