// @vendors
import React, { memo, useMemo } from 'react';

// @assets
import { EmployerStyles } from '../../../assets/css/employer-style';
import { StatsStyles } from '../../../assets/css/statsBoxes-style.js';

// @components material
import {
  AssignmentTurnedInOutlinedIcon,
  Card,
  CardContent,
  Grid,
  PersonAddAlt1OutlinedIcon,
  PersonRemoveOutlinedIcon,
  Typography,
} from '../../../components/shared/MaterialUI.js';

// @components
import EmployerProfilePic from './EmployerProfilePic';

// @services
import { CircularProgress } from '@mui/material';

const EmployerStats = memo(({ id, stats }) => {
  const styles = useMemo(() => EmployerStyles(),[]);
  const stylesStas = useMemo(() => StatsStyles(),[]);
  let boxes = [
    {
      title: 'Number of employees',
      type: 'employees',
      icon: <PersonAddAlt1OutlinedIcon sx={styles.statsIcon} />,
    },
    {
      title: 'Completed questionnaires',
      type: 'questionnaireCompleted',
      icon: <AssignmentTurnedInOutlinedIcon sx={styles.statsIcon} />,
    },
    {
      title: 'Abandoned questionnaires',
      type: 'questionnaireAbandoned',
      icon: <PersonRemoveOutlinedIcon sx={styles.statsIcon} />,
    },
  ];

  return (
    <Grid
      component={'section'}
      sx={stylesStas.container}
    >
      <EmployerProfilePic id={id}/>
      {React.Children.toArray(
      boxes.map((box) => {
        return (
            <Card elevation={3} sx={styles.statsBox}>
              {box.icon}
              <CardContent>
                <Typography>{box.title}</Typography>
                {
                  Object.entries(stats).length === 0
                    ? <Typography>
                        <CircularProgress color="inherit" size="20px"/>
                      </Typography>
                    : <Typography>{stats[box.type] || 0}</Typography>
                }
                
              </CardContent>
            </Card>
        );
      })
    )}
    </Grid>
  );
});

export default EmployerStats;
