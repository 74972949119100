// @vendors
import React, { useContext, useMemo, useState } from 'react'

// @material-ui
import {TableCell, TableRow} from "../../../../components/shared/MaterialUI";

// @helpers
import {
  formattDate,
  getTargetGroup,
  getTargetGroupTooltip,
  HtmlTooltip,
  validateEmptyString,
} from '../../../../helpers/FunctionsUtils';

// @assets
import { PayrollStyle } from '../../../../assets/css/payroll-style';

// @components
import { payrollContext } from '../../payrollView';
import YTDModal from './YTDModal';
import { useUI } from '../../../../app/context/ui';
import PayrollService from '../../../../services/newApi/PayrollService';
import AppHelper from '../../../../helpers/AppHelper';

const PayrollRow = ({item}) => {
  const style = useMemo(() => PayrollStyle(), []);
  const {currentTab} = useContext(payrollContext);
  const [open, setOpen] = useState(false);
  const { blockUI, snackbarUI } = useUI();
  const payrollService = useMemo(() => new PayrollService(), []);
  const [dataYtd, setDataYtd] = useState([]);

  const getYtdValue = async () => {
    try {
        blockUI.current.open(true);
        const response = await payrollService.getYtdByEmployees(item?.employeeId);
        setDataYtd(response?.data?.data);
        blockUI.current.open(false);
    } catch (error) {
        blockUI.current.open(false);
        AppHelper.checkError(error, snackbarUI);
    }
  }  

  const handleOpenModal = () => {
    getYtdValue();
    setOpen(true);
  }
  const handleCloseModal = () => setOpen(false);

  const employerName = (name) => {
    if(name?.length > 40)return `${name?.substring(0, 40)}...`;
    return name;
  }

  return (
    <>
      <TableRow key={item?.id} sx={{display:'flex'}}>
          <TableCell sx={style.tableCellRow} >{formattDate(item?.lastSyncDate)}</TableCell>
          <TableCell sx={style.tableCellRow} >{validateEmptyString(item?.stateStatus)}</TableCell>
          <TableCell sx={style.tableCellRow} >{item?.employeeFullName}</TableCell>
          <TableCell sx={style.tableCellRow} >{employerName(item?.employeer)}</TableCell>
          <TableCell sx={style.tableCellRow} >{item?.employeerRate}</TableCell>
              <TableCell sx={style.tableCellRow} >
          <HtmlTooltip title={getTargetGroupTooltip(item?.targetGroups) ?? ''} placement="right-start">
                  {getTargetGroup(item?.targetGroups, false)}
          </HtmlTooltip>
              </TableCell>
          <TableCell sx={style.tableCellRow} >{getTargetGroup(item?.targetGroups, true)}</TableCell>

          <TableCell sx={style.tableCellRow} >{formattDate(item?.startDate)}</TableCell>

          <TableCell sx={style.tableCellRow} >{validateEmptyString(item?.paySchedule)}</TableCell>
          
          {currentTab === 2 && <TableCell sx={style.tableCellRow} >{item?.maxedOutReason}</TableCell>}
          <TableCell
            sx={style.tableCellRow}
            onClick={() => handleOpenModal()}
          >
            {item?.currentYTDGrossWages}
          </TableCell>

          <TableCell 
            sx={style.tableCellRow}
            onClick={() => handleOpenModal()}
          >
            {item?.currentYTDHours}
          </TableCell>

          <TableCell sx={style.tableCellRow} >{item?.wotcCreditMultiplier}</TableCell>

          <TableCell sx={style.tableCellRow} >{item?.maxWage}</TableCell>

          <TableCell sx={style.tableCellRow} >{item?.maxTaxCredit}</TableCell>

          <TableCell sx={style.tableCellRow} >{item?.taxCreditYTD}</TableCell>

          <TableCell 
            sx={style.tableCellRow}
            onClick={() => handleOpenModal()}
          >
            {item?.invoiceYTD}
          </TableCell>

          <TableCell 
            sx={style.tableCellRow}
            onClick={() => handleOpenModal()}
          >
            {item?.actualInvoice}
          </TableCell>
      </TableRow>

      <YTDModal
        key={item?.id}
        open={open}
        handleClose={handleCloseModal}
        dataYtd={dataYtd}
      />
    </>
  )
}

export default PayrollRow