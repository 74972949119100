/* eslint-disable react-hooks/exhaustive-deps */
// @vendors
import React, {createContext, useEffect, useState} from 'react'
import {Container, Grid} from '@mui/material';
import { useDispatch } from 'react-redux';

// @components
import ListOrganizations from './ListOrganizations';
import Pageheader from '../../components/PageHeader';

// @context
import { useUI } from '../../app/context/ui';

// @helpers
import { getOrganizations } from '../../helpers/FunctionsUtils';

// @assets
import { DashboardStyles } from '../../assets/css/dashboard-style';
import { OrganizationStyle } from '../../assets/css/employeeTable-style';

// @redux
import { types } from '../../redux/types';
import store from '../../redux/store';

const OrganizationScreen = () => {
  const { blockUI, snackbarUI } = useUI();
  const styles = DashboardStyles();
  const stylesOrganization = OrganizationStyle();
  const dispatch = useDispatch()
  const state = store.getState();

  const [rowsState, setRowsState] = useState([]);

  const getListOrganization = async (hasDispatch=false) => {
    const response = await getOrganizations(snackbarUI, blockUI);
    setRowsState(response?.data?.data);
    if(hasDispatch){
      const organizationSelected = response?.data?.data?.find((item) => item?.id === state?.organizationReducer?.organizationSelected?.id);
      dispatch({
        type: types.ORGANIZATIONS_LIST,
        data: response?.data?.data,
        organizationSelected: organizationSelected ?? ''
      })
    }
  }

  useEffect(() => {
    getListOrganization();
  }, [])

  return (
    <Container
      component="section"
      maxWidth="xl"
      sx={styles.dashCtn}
    >
      <Container
        component="section"
        maxWidth="xl"
        sx={stylesOrganization.containerTitle}
      >
        <Pageheader
          title={'Organizations'}
          subtitle={'Welcome to organizations dashboard'}
        />
      </Container>
      <Grid>
          <Organization.Provider value={{rowsState, getListOrganization}}>
            <ListOrganizations/>
          </Organization.Provider>
      </Grid>
    </Container>
  )
}

export const Organization = createContext({});
export default OrganizationScreen