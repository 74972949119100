// @vendors
import React, { useContext, useState, useRef, useMemo} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @state
import { useUI } from "../../../../../app/context/ui";
import { EmployeeContext } from "../../../Employer";

// @assets
import { EmployeeTableStyles } from "../../../../../assets/css/employeeTable-style";

// @components
import DialogUI from "../../../../../components/shared/DialogUI";
import {
  ArrowForwardIcon,
  ArrowUpwardIcon,
  Box,
  Button,
  ClearAllIcon,
  FilterListRoundedIcon,
  Input,
  IconButton,
  InputAdornment,
  SearchRoundedIcon,
  TextField,
  Tooltip,
  Link,
  Grid,
  FileUploadRoundedIcon,
  FileDownloadOutlinedIcon,
} from "../../../../../components/shared/MaterialUI";
import { AddNewEmployee } from "./AddEmployee";
import FilterStatus from "./FilterStatus";
import CustomDialog from "../../CustomDialog/CustomDialog";
import MenuAddBranch from "../../childs/MenuAddBranch";

// @helperts
import AppHelper from "../../../../../helpers/AppHelper";
import {
  downloadTemplate,
  formDataUploadEmployees,
  permissionsForAddOrEditEmployees,
  permissionsForEditEmployer,
} from "../../../../../helpers/FunctionsUtils";

// @services
import SubmitterService from "../../../../../services/newApi/SubmitterServiceNewApi";
import CompanyService from "../../../../../services/newApi/CompanyServiceNewApi";
import EmployeeCompanyServiceNewApi from "../../../../../services/newApi/EmployeeCompanyServiceNewApi";
import PayrollService from "../../../../../services/newApi/PayrollService";

// @constants
import { ROUTENAME } from "../../../../../navigation/RouteName";

const TableToolbar = () => {
  const {
    getAllEmployesByCompany,
    setRowsState,
    rowsState,
    companyId,
    setShowChildrens,
    listChildrens,
    childReachLimit,
    employerDetails,
    getAllChildrens,
  } = useContext(EmployeeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const styles = useMemo(() => EmployeeTableStyles(),[]);
  const id = open ? "simple-popover" : undefined;
  const [sendQuestionnaire, setSendQuestionnaire] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const openMenu = Boolean(anchorElMenu);
  const history = useHistory();

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const {
    blockUI,
    dialogUI,
    snackbarUI,
    progressUI,
  } = useUI();
  const customDialogRef = useRef(null);
  const user = useSelector((loadedState) => loadedState.user);
  const companyService = useMemo(() => new CompanyService(), []);
  const submitterService = useMemo(() => new SubmitterService(), []);
  const employeeServiceNewApi = useMemo(() => new EmployeeCompanyServiceNewApi(), []);
  const payrollService = useMemo(() => new PayrollService(), []);

  const settings = {
    confirm: true,
    btn: {
      confirm: "Close",
      close: "",
    },
    onConfirm: () => {
      dialogUI.current.close();
      getAllEmployesByCompany({filters:{}});
    },
    styles: { ...styles.dialog },
  };

  const submitSettings = {
    confirm: true,
    btn: {
      confirm: "Close",
      close: "",
    },
    onConfirm: () => dialogUI.current.close(),
    styles: { ...styles.dialog },
  };

  const uploadFile = async (e) => {
    if (e.target.files[0]) {
      const file = formDataUploadEmployees(e.target.files[0]);
      try {
        blockUI.current.open(true);
        await employeeServiceNewApi.chargeCsvFile(file, companyId);
        blockUI.current.open(false);
        dialogUI.current.open(
          "Great!",
          "Records were imported successfully!",
          settings
        );
      } catch (err) {
        blockUI.current.open(false);
        AppHelper.checkError(err, snackbarUI);
        e.target.value = "";
      }
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const submitQuestionnaire = async () => {
    try {
      progressUI.current.open(
        "Your requests are being processed, please wait a moment",
        "",
        "success"
      );
      submitterService.setAccessToken(user.accessToken);
      const { data: all } = await submitterService.sendFormToState(
        companyId,
        user?.id
      );
      const { data } = all;

      progressUI.current.close();

      if (data.id) {
        return customDialogRef.current.open(
          "The forms have be successfully processed.",
          data
        );
      } else {
        dialogUI.current.open(
          "Thank you !",
          "There are no questionnaires ready for submission at this moment. Please try again later.",
          submitSettings
        );
      }
    } catch (e) {
      AppHelper.checkError(e, snackbarUI);
      progressUI.current.close();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resendQuestionnaires = async () => {
    try {
      blockUI.current.open(true);
      const res = await companyService.resendQuestionnaire(companyId);
      const message = res.data.message;
      dialogUI.current.open("Congratulations!", message, submitSettings);
      setSendQuestionnaire(true);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const downloadEmployees = async () => {
    try {
      blockUI.current.open(true);
      const res = await employeeServiceNewApi.exportEmployeesByCompany(
        companyId
      );
      fileToConvert(res);
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const fileToConvert = (res) => {
    const blob = new Blob([res?.data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "employees.csv";
    link.click();
  };

  const onSelectChange = (status) => {
    setRowsState((prev) => ({
      ...prev,
      filters: {...prev.filters, status: status}
    }));
    const filters = { name: rowsState.filters.name, status: status };
    getAllEmployesByCompany({filters: filters});
    setAnchorEl(null);
  };

  const handleSearchBar = async (query) => {
    if (query) getAllEmployesByCompany();
  };

  const onSearchBarChange = (e) => {
    setRowsState((prev) => ({
      ...prev,
      filters: {...prev.filters, name: e.target.value}
    }));
  };

  const clearSearch = () => {
    setRowsState((prev) => ({
      ...prev,
      filters: {status: "", name: ""}
    }));
    setAnchorEl(null);
    getAllEmployesByCompany({filters: {}});
  };

  const actionToSelect = () => {
    if(rowsState.filters?.name === '')return clearSearch();
    if(rowsState.filters?.name?.length > 0)return handleSearchBar(rowsState.filters?.name);
    return
  }

  const getEmployeeTemplate = async () => {
    try {
      blockUI.current.open(true);
      const response = await employeeServiceNewApi.donwloadTemplete();
      blockUI.current.open(false);
      return downloadTemplate(response?.data, 'template_employees.csv');
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  const uploadFilePayroll = async (e) => {
    if (e.target.files[0]) {
      const originalFile = e.target.files[0];
      const file = formDataUploadEmployees(originalFile);
      return sendData(e, file);
    }
    return;
  };

  const sendData = async (e, file) => {
    e.target.value = "";
    try {
      blockUI.current.open(true);
      await payrollService.uploadManual(file);
      blockUI.current.open(false);
      snackbarUI.current.open('File saved successfully');
    } catch (error) {
      blockUI.current.open(false);
      if(error?.response?.data)return snackbarUI.current.open(error?.response?.data);
      AppHelper.checkError(error, snackbarUI);
    }
  }

  return (
    <Box sx={styles.tableToolbar}>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <h2>Employee list</h2>
        </Grid>

        <Grid item xs={12} sm={8} md={6} lg={5} sx={{display: 'flex', gap: '10px', alignItems: 'center'}}>
          <TextField
            sx={{...styles.searchInput, ...styles.btnStyleSm}}
            value={rowsState.filters?.name}
            id="outlined"
            placeholder="Search"
            onChange={(e) => onSearchBarChange(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if(rowsState.filters?.name === '') return clearSearch()
                return handleSearchBar(rowsState.filters?.name);
              }
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={actionToSelect}>
                    <SearchRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Clear filter">
            <IconButton
              onClick={() => clearSearch()}
              sx={{width: '40px', height: '40px'}}
            >
              <ClearAllIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2}>
          <Tooltip title="Show filters">
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
              sx={{...styles.filterButton, ...styles.btnStyleSm, width: '100%'}}
            >
              Filter
              <FilterListRoundedIcon />
            </Button>
          </Tooltip>
        </Grid>

        {permissionsForAddOrEditEmployees(user?.role) &&
          <>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <AddNewEmployee
                companyId={companyId}
                getEmployees={getAllEmployesByCompany}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={2.5}>
              <Tooltip title="Import">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    sx={{ display: "none" }}
                    onChange={(e) => uploadFile(e)}
                  />
                  <Button
                    variant="contained"
                    component="span"
                    sx={{...styles.importButton, ...styles.btnStyleSm}}
                  >
                    Bulk Import
                    <ArrowUpwardIcon />
                  </Button>
                </label>
              </Tooltip>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={3}>
              <Tooltip title="Re-send questionnaires">
                <Button
                  variant="contained"
                  sx={{...styles.submitListButton, ...styles.btnStyleSm}}
                  onClick={resendQuestionnaires}
                >
                  {sendQuestionnaire
                    ? "Re-sent questionnaires"
                    : "Re-send questionnaires"}
                  <ArrowForwardIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={1.5}>
              <Tooltip title="Export">
                <Link download onClick={downloadEmployees} sx={styles.exportLink}>
                  <Button
                    variant="contained"
                    component="span"
                    sx={{...styles.importButton, ...styles.btnStyleSm}}
                  >
                    Export
                  </Button>
                </Link>
              </Tooltip>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={1.5}>
              <Link download onClick={() => getEmployeeTemplate()} sx={styles.downloadLink}>
                <Button
                  sx={{...styles.csvButton, ...styles.btnStyleSm}}
                  endIcon={<FileDownloadOutlinedIcon />}
                >
                  Template
                </Button>
              </Link>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={1.5}>
              <Input
                accept=".csv"
                id="contained-button-file-upload-csv"
                type="file"
                sx={{ display: "none" }}
                onChange={(e) => uploadFile(e)}
              />
              <label htmlFor="contained-button-file-upload-csv">
                <Button
                  variant="contained"
                  component="span"
                  sx={{...styles.csvButton, ...styles.btnStyleSm}}
                >
                  Upload
                  <FileUploadRoundedIcon />
                </Button>
              </label>
            </Grid>

            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Tooltip title="Submit">
                <Button
                  variant="contained"
                  sx={{...styles.submitButton, ...styles.btnStyleSm, width: '100%'}}
                  onClick={submitQuestionnaire}
                >
                  Submit
                  <ArrowForwardIcon />
                </Button>
              </Tooltip>
            </Grid>

            {!childReachLimit && permissionsForEditEmployer(user?.role)
              ? <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Tooltip title="Add new branch">
                    <Button
                      variant="contained"
                      sx={{...styles.csvButton, ...styles.btnStyleSm, width: '100%'}}
                      onClick={handleClickMenu}
                      aria-controls={openMenu ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openMenu ? 'true' : undefined}
                    >
                      Add new branch
                    </Button>
                  </Tooltip>
                </Grid>
              : null
            }

          </>
        }

        {listChildrens?.rows?.length
          ? <Grid item xs={12} sm={6} md={3} lg={2}>
              <Tooltip title="Show children">
                <Button
                  variant="contained"
                  sx={{...styles.csvButton, ...styles.btnStyleSm, width: '100%'}}
                  onClick={() => setShowChildrens(true)}
                >
                  show children
                </Button>
              </Tooltip>
            </Grid>
          : null
        }

        {!listChildrens?.rows?.length && employerDetails?.data?.parentCompanyId
          ? <Grid item xs={12} sm={6} md={3} lg={2}>
              <Tooltip title="Back to parent">
                <Button
                  variant="contained"
                  sx={{...styles.csvButton, ...styles.btnStyleSm, width: '100%'}}
                  onClick={() => {
                    history.push(`${ROUTENAME.employer}${employerDetails?.data?.parentCompanyId}`)
                    getAllChildrens(employerDetails.data.parentCompanyId)
                }}
                >
                   back to parent
                </Button>
              </Tooltip>
            </Grid>
          : null
        }
        
        {employerDetails?.data?.mainParentCompanyId
          ? <Grid item xs={12} sm={6} md={3} lg={3}>
              <Tooltip title="Back to main parent">
                <Button
                  variant="contained"
                  sx={{...styles.csvButton, ...styles.btnStyleSm, width: '100%'}}
                  onClick={() => {
                    history.push(`${ROUTENAME.employer}${employerDetails?.data?.mainParentCompanyId}`);
                    getAllChildrens(employerDetails.data.mainParentCompanyId);
                  }}
                >
                  back to main parent
                </Button>
              </Tooltip>
            </Grid>
          : null
        }

        <Tooltip title="Payroll">
          <Grid item xs={12} sm={4} md={2} lg={1.5}>
              <label htmlFor="contained-button-file-payroll">
                <input
                  type='file'
                  accept=".csv"
                  id="contained-button-file-payroll"
                  style={{display: 'none'}}
                  onChange={(e) => uploadFilePayroll(e)}
                />
                <Button
                  variant="contained"
                  component="span"
                  sx={{...styles.csvButton, ...styles.btnStyleSm, width: '100%'}}
                >
                  Payroll
                  <ArrowUpwardIcon />
                </Button>
              </label>
          </Grid>
        </Tooltip>

        <FilterStatus
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          onSelectChange={onSelectChange}
          clearSearch={clearSearch}
        />
      </Grid>
      <DialogUI ref={dialogUI} companyId={companyId} />
      <CustomDialog onUpdate={getAllEmployesByCompany} ref={customDialogRef} />
      <MenuAddBranch
          anchorElMenu={anchorElMenu}
          openMenu={openMenu}
          handleCloseMenu={handleCloseMenu}
      />
    </Box>
  );
};

export default TableToolbar;
