// @vendors
import React, { useContext, useMemo } from 'react'
import { Grid, Menu, MenuItem } from '@mui/material'

// @context
import { useUI } from '../../../../app/context/ui';
import { DataTable } from '../../Admin';

// @services
import CompanyServiceNewApi from '../../../../services/newApi/CompanyServiceNewApi';

// @helpers
import { downloadTemplate, formDataUploadEmployees } from '../../../../helpers/FunctionsUtils';
import AppHelper from '../../../../helpers/AppHelper';

// @assets
import { EmployeeTableStyles } from '../../../../assets/css/employeeTable-style';
import { useSelector } from 'react-redux';

const MenuFileCompany = ({
    anchorElMenu,
    openMenu,
    handleCloseMenu,
    isUpload
}) => {
    const { blockUI, dialogUI, snackbarUI } = useUI();
    const stylesDialog = useMemo(() => EmployeeTableStyles(), []);
    const organization = useSelector((state) => state?.organizationReducer?.organizationSelected);
    const companyServiceNewApi = useMemo(() => new CompanyServiceNewApi(), []);
    const { getAllCompanies } = useContext(DataTable);

    const settings = {
        confirm: true,
        btn: {
          confirm: "Close",
          close: "",
        },
        onConfirm: () => {
          dialogUI.current.close();
          getAllCompanies();
        },
        styles: { ...stylesDialog.dialog },
    };

    const getNewTemplate = async (isNew) => {
        const templeteName = isNew ? 'template_add_employer.csv' : 'template_update_employer.csv';
        try {
          blockUI.current.open(true);
          const response = isNew ? await companyServiceNewApi.getTempleteForAddEmployers() : await companyServiceNewApi.getTempleteForUpdateEmployers();
          blockUI.current.open(false);
          handleCloseMenu();
          return downloadTemplate(response?.data, templeteName)
        } catch (e) {
          throwError(e);
        }
    };
    
    const uploadCsvFile = async (e, isNew) => {
        if (e.target.files[0]) {
          const file = formDataUploadEmployees(e.target.files[0]);
          try {
            blockUI.current.open(true);
            isNew ? await companyServiceNewApi.addEmployerByFile(file, organization?.id) : await companyServiceNewApi.updateEmployerByFile(file);
            blockUI.current.open(false);
            successUploadFile(e);
            handleCloseMenu();
          } catch (err) {
            throwError(err);
            e.target.value = "";
          }
        }
    };

    const successUploadFile = (e) => {
        dialogUI.current.open(
          "Great!",
          "Records were uploaded successfully!",
          settings
        );
        e.target.value = "";
    }

    const throwError = (error) => {
        blockUI.current.open(false);
        AppHelper.checkError(error, snackbarUI);
    }

  return (
    <Menu
        id="basic-menu"
        anchorEl={anchorElMenu}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
    >
        {isUpload
            ?   <Grid>
                    <input
                        type='file'
                        accept=".csv"
                        id="contained-button-file-new-csv-employer"
                        style={{display: 'none'}}
                        onChange={(e) => uploadCsvFile(e, true)}
                    />
                    <label htmlFor="contained-button-file-new-csv-employer">
                        <MenuItem>New</MenuItem>
                    </label>
                    <input
                        type='file'
                        accept=".csv"
                        id="contained-button-file-upload-csv-employer"
                        style={{display: 'none'}}
                        onChange={(e) => uploadCsvFile(e, false)}
                    />
                    <label htmlFor="contained-button-file-upload-csv-employer">
                        <MenuItem>Update</MenuItem>
                    </label>
                </Grid>
            :   <Grid>
                    <MenuItem onClick={() => getNewTemplate(true)}>New</MenuItem>
                    <MenuItem onClick={() => getNewTemplate(false)}>Update</MenuItem>
                </Grid>
        }
    </Menu>
  )
}

export default MenuFileCompany