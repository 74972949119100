// @vendors
import React, { useEffect, useMemo, useState } from 'react';

// @material-ui
import { Typography, Box} from '../../../../../components/shared/MaterialUI.js';

// @assets
import { QuestionnaireResultsStyles } from '../../../../../assets/css/employeeTable-style.js';

// @state
import { useUI } from '../../../../../app/context/ui';

// @helpers
import AppHelper from '../../../../../helpers/AppHelper';

// @components
import StringAnswerResult from './StringAnswerResult.js';
import ArrayAnswerResult from './ArrayAnswerResult.js';

// @services
import QuestStartServiceNewApi from '../../../../../services/newApi/QuestionnaireServiceNewApi.js';

const QuestionnaireResults = (props) => {
  const { blockUI, snackbarUI } = useUI();
  const styles = useMemo(() => QuestionnaireResultsStyles(), []);
  const [employeeAnswers, setEmployeeAnswers] = useState([]);
  const idEmployee = props.id;
  const questServiceNewApi = useMemo(() => new QuestStartServiceNewApi(), []);

  const answersAdapter = (answers) => {
    return answers
  }

  const getAnswers = async (id) => {
    try {
      blockUI.current.open(true);
      const r1 = await questServiceNewApi.getAnswersByEmployee(id);
      if (r1?.status === 200 || r1?.status === 201) {
        const results = answersAdapter(r1?.data?.data)
        setEmployeeAnswers(results);
      }
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  };

  useEffect(() => {
    getAnswers(idEmployee);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Box sx={styles.mainBox}>
        <Box sx={styles.subBox}>
          <Typography variant='h1'>Results</Typography>
          <Typography variant='h3'>Questionnaire Results of employee</Typography>
          <Box sx={styles.qaBox}>
            {employeeAnswers?.answerGroup?.map((blockqa, i) => (
              Array.isArray(blockqa?.answerList) ? (
                <ArrayAnswerResult key={i} question={blockqa} pos={i + 1} />
              ) : (
                <StringAnswerResult key={i} question={blockqa} pos={i + 1} />
              )
            ))}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default QuestionnaireResults;
